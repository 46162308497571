import React from 'react'
import {
    Card,
    CardBody,
    Box,
    Heading,
    Center,
    Button,
    Flex,
    Stack,
    HStack
} from '@chakra-ui/react'

const FeatureCard = (props) => {

    return (
        <>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                onClick={() => props.onClick}
                w={[350, 450, 650]}
                boxShadow="md"
                mb="0px"
            >
                <Box
                    width="100%"
                >
                    <HStack>
                        <CardBody
                            p="5px"
                        >
                            <Stack>
                                <HStack>
                                    <Box
                                        m="0px 0px 0px 4px"
                                        width="48px"
                                        color="#63b3ed"
                                    >
                                        {props.icon}
                                    </Box>
                                    <Heading
                                        m="0px 0px 4px 8px"
                                        size='md'
                                    >
                                        {props.title}
                                    </Heading>
                                </HStack>
                                <Flex>
                                    <Center
                                        m="0px 0px 0px 8px"
                                        flex="1"
                                    >
                                        {props.summary}
                                    </Center>
                                    <Button
                                        colorScheme="blue"
                                        width="100px"
                                        m="0px 4px 0px 4px"
                                        onClick={() => props.onClick()}
                                    >
                                        {props.title}
                                    </Button>
                                </Flex>
                            </Stack>
                        </CardBody>
                    </HStack>
                </Box>
            </Card>          
        </>      
    )
}

export default FeatureCard