import {
    getWordMediaUrls,
    getSentenceMediaUrl,
    getValueFromWord
} from "../../WordDataFunction"
import { removeLinkTags } from "../../CommonFunction"

export function buildStudyWordProps(wordData, selectedMeaningSentence, sases) {
    
    const anotherSentenceMeaning = getValueFromWord(
        wordData,
        "sentenceMeaning",
        selectedMeaningSentence.meaningIndex,
        selectedMeaningSentence.anotherSentenceIndex
    )

    const wordAudioUrls = getWordMediaUrls(wordData, "audio", sases)
    const wordImageUrl = getWordMediaUrls(wordData, "image", sases)
    const sentenceAudioUrl = getSentenceMediaUrl(
        wordData,
        "audio",
        sases,
        selectedMeaningSentence.meaningIndex,
        selectedMeaningSentence.sentenceIndex
    )
    const sentenceImageUrl = getSentenceMediaUrl(
        wordData,
        "image",
        sases,
        selectedMeaningSentence.meaningIndex,
        selectedMeaningSentence.sentenceIndex
    )

    const initialFieldStatus = {
        listenWordAudio: wordAudioUrls.length > 0 ? 0 : 1,
        recordWordAudio: wordAudioUrls.length > 0 ? 0 : 1,
        listenSentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? 0 : 1,
        recordSentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? 0 : 1,
        matchWordText: 0,
        writingText: anotherSentenceMeaning && anotherSentenceMeaning.length > 0 ? 0 : 1
    }

    const stepsAvailability = {
        wordAudio: wordAudioUrls.length > 0,
        matchWordText: true,
        sentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? true : false,
        writingText: anotherSentenceMeaning && anotherSentenceMeaning.length > 0
    }

    return {
        wordId: getValueFromWord(wordData, "wordId"),
        meaningIndex: selectedMeaningSentence.meaningIndex,
        sentenceIndex: selectedMeaningSentence.sentenceIndex,
        anotherSentenceIndex: selectedMeaningSentence.anotherSentenceIndex,
        cId: getValueFromWord(wordData, "cId"),
        displayName: getValueFromWord(wordData, "displayName"),
        pos: getValueFromWord(wordData, "pos", selectedMeaningSentence.meaningIndex),
        pronunciation: getValueFromWord(wordData, "pronunciation"),
        episode: getValueFromWord(wordData, "episode"),
        meaning: removeLinkTags(
            getValueFromWord(
                wordData,
                "meaning",
                selectedMeaningSentence.meaningIndex
            )
        ),
        tags: getValueFromWord(
            wordData,
            "tags",
            selectedMeaningSentence.meaningIndex
        ),
        synonyms: getValueFromWord(
            wordData,
            "synonyms",
            selectedMeaningSentence.meaningIndex
        ),
        usages: getValueFromWord(
            wordData,
            "usages",
            selectedMeaningSentence.meaningIndex
        ),
        compliments: getValueFromWord(
            wordData,
            "compliments",
            selectedMeaningSentence.meaningIndex
        ),
        sentence: getValueFromWord(
            wordData,
            "sentence",
            selectedMeaningSentence.meaningIndex,
            selectedMeaningSentence.sentenceIndex
        ),
        sentenceMeaning: getValueFromWord(
            wordData,
            "sentenceMeaning",
            selectedMeaningSentence.meaningIndex,
            selectedMeaningSentence.sentenceIndex
        ),
        notes: getValueFromWord(wordData, "notes"),
        anotherSentenceMeaning,
        wordAudioUrls,
        wordImageUrl,
        sentenceAudioUrl,
        sentenceImageUrl,
        initialFieldStatus,
        stepsAvailability
    }    
}