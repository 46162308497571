import React, { useState, useRef, useEffect }  from "react";
import { useFormik } from "formik";
import {
    Button,
    Box,
    Center,
    Text,
    HStack,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex,
    Spacer,
    useDisclosure
} from "@chakra-ui/react";

import useApi from "../../hooks/useApi"
import "../../App.css";
import {
    maskWord,
    normalizeString
} from "../../CommonFunction"
import QuizSentence from "./QuizSentence"
import {useUserContext} from "../../context/userContext"
import ContentHeading from "../ContentHeading"
import SearchBox from "../search/SearchBox"
import ImageElement from "../ImageElement"
import Timer from "../Timer"
import BackButton from "../BackButton"
import HeaderComponent from "../HeaderComponent"

import { ReactComponent as SlightlySmilingFaceFlat } from "../../assets/icons/slightly_smiling_face_flat.svg"
import { ReactComponent as NeutralFaceFlat } from "../../assets/icons/neutral_face_flat.svg"
import { ReactComponent as SadButRelievedFaceFlat } from "../../assets/icons/sad_but_relieved_face_flat.svg"

const QuizWordAdvance = (props) => {
    const maxElapseSeconds = 30
    const warningSeconds = 10
    
    const {
        getFsrsRetention             
    } = useUserContext()

    const [accordionIndex, setAccordionIndex] = useState(-1)
    const [startTime, setStartTime] = useState(new Date())
    const [elapsedSeconds, setElapsedSeconds] = useState(maxElapseSeconds)


    const {commitActivity} = useApi()

    const displayNameRef = useRef("")
    const onSubmit = async (answerStatus, rating) => {
        if (displayNameRef.current !== props.params.displayName) {
            displayNameRef.current = props.params.displayName
            const quizHistory = props.params.quizHistory
            
            await commitActivity(
                props.params.wordId,
                props.params.cId,
                props.params.activity,
                props.params.displayName,
                props.params.meaning,
                {
                    "elapsedSeconds": elapsedSeconds,
                    answerStatus,
                    rating,
                    quizHistory,
                    "fsrsRetention": getFsrsRetention()
                }
            )

            window.scrollTo(0, 0)
            formik.values.displayName = ""
            setAccordionIndex(-1)

            props.dispatch({
                type: "quizAnswer",
                data: {
                    previous: {},
                    current: {
                        meaning: props.params.meaning,
                        cId: props.params.cId,
                        wordId: props.params.wordId,
                        displayName: props.params.displayName,
                        isCorrect: answerStatus === "correct",
                    }
                }                
            })
            setTimerFlag(`restart_${Math.random()}`)
        }
    }

    ///////////////////////////////////////////////
    // timer location
    
    const [timerLocation, setTimerLocation] = useState(getTimerLocation())
    function getTimerLocation() {
        const bottom = 90
        const leftOffset = 220
        const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        const panelWidth = Math.min(viewportWidth, 810)
        let left = 0
        if (viewportWidth > panelWidth) {
            left = (viewportWidth - panelWidth) / 2 + panelWidth - leftOffset
        } else {
            left = viewportWidth - leftOffset
        }
        return {left, bottom}
    }
    window.addEventListener('load', (e) => {
        setTimerLocation(getTimerLocation())
        // console.log(`timerLeftLocation: ${JSON.stringify(timerLocation)}`)
    })
    window.addEventListener('resize', (e) => {
        setTimerLocation(getTimerLocation())
        // console.log(`timerLeftLocation: ${JSON.stringify(timerLocation)}`)
    })

    ///////////////////////////////////////////////    

    const formik = useFormik({
        initialValues: {
            displayName: "",
        }
    })

    const hintSection = 
        props.params.displayName ?
            <>
                <HStack
                    pb="6px"
                >
                    <Box
                        bgColor="blue.100"
                        fontSize="xs"
                    >
                        単語
                    </Box>
                    <Box
                        flex="1"
                    >
                        {maskWord(props.params.displayName)}
                    </Box>
                </HStack>

                <HStack
                    pb="16px"
                >
                    <Box
                        bgColor="blue.100"
                        fontSize="xs"
                    >
                        例文
                    </Box>
                    <Box flex="1">
                        <QuizSentence
                            quizSentence={props.params.quizSentence}
                        />
                    </Box>
                </HStack>

                <ImageElement
                    url={props.params.sentenceImageUrl}
                    maxW="200px"
                    maxH="200px"                
                />
            </>
            :
            <></>

    const { 
        isOpen: isCorrectOpen,
        onOpen: onCorrectOpen,
        onClose: onCorrectClose
    } = useDisclosure()
    const { 
        isOpen: isIncorrectOpen, 
        onOpen: onIncorrectOpen, 
        onClose: onIncorrectClose
    } = useDisclosure()
    const { 
        isOpen: isTimeoutOpen, 
        onOpen: onTimeoutOpen, 
        onClose: onTimeoutClose
    } = useDisclosure()

    const [timerFlag, setTimerFlag] = useState("")
    const timeoutRef = useRef()

    const getCorrectAnswer = () => {
        return `正解: ${props.params.displayName}`
    }

    ////////////////////////////////////////////////////
    // setStartTime
    useEffect(() => {
        setStartTime(new Date())
    }, [props.params.wordId, setElapsedSeconds])


    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"
        >
            <Flex
                height="28px"
                width="200px"
                position="fixed"
                zIndex="200"
                left={`${timerLocation.left}px`}
                bottom={`${timerLocation.bottom}px`}
            >
                <Spacer />
                <Timer
                    timerSeconds={maxElapseSeconds}
                    warningSeconds={warningSeconds}
                    timerFlag={timerFlag}
                    timeoutFunc={() => {
                        if (!isCorrectOpen && !isIncorrectOpen) {
                            timeoutRef.current.click()
                        }
                    }}
                />
            </Flex>

            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <>
                        <ContentHeading 
                            title={`英単語クイズ: ${props.params.quizIndex + 1} / ${props.params.quizCount} 問目`}
                        />
                        <Box
                            m="6px 0px 0px 16px"
                        >
                            次の意味の英単語か英熟語を記述してください
                        </Box>
                    </>
                }
            />                 

            <Box 
                rounded="md"
                border="0px 0px 10px 0px"
                borderColor="blue.100"
                padding={"0px 10px 30px 10px"}
                margin="0% 5% 0% 5%"
            >
                <Stack>
                    <Box>
                        <Text 
                            fontSize="5xl"
                        >
                            {props.params.meaning}
                        </Text>
                    </Box>
                    <Box
                        mb="24px"
                    >
                        <SearchBox 
                            inputId={"displayName"}
                            formik={formik}
                            placeholder={"単語を正確に記述しましょう"}
                            buttonLabel={"回答"}
                            allowEmptyInput={true}
                            onClickFunc={() => {
                                console.log(`endTime:${new Date()}`)
                                setElapsedSeconds(Math.round((new Date() - startTime) / 1000),)

                                if (normalizeString(formik.values.displayName) === normalizeString(props.params.displayName)) {
                                    onCorrectOpen()
                                    // setTimeout(() => {
                                    //     onCorrectClose()
                                    //     onSubmit("correct", "good")
                                    // }
                                    // , 1500)
                                    console.log(`isCorrectOpen():${isCorrectOpen}`)
                                } else {
                                    onIncorrectOpen()
                                    setTimeout(() => {
                                        onIncorrectClose()
                                        onSubmit("incorrect", "again")
                                    }
                                    , 1500)
                                }
                            }}
                        />
                        <Button
                            ref={timeoutRef}
                            style={{ display: 'none' }}
                            onClick={()=>{
                                console.log(`endTime:${new Date()}`)
                                setElapsedSeconds(Math.round((new Date() - startTime) / 1000),)
                                onTimeoutOpen()
                                setTimeout(() => {
                                    onTimeoutClose()
                                    onSubmit("incorrect", "again")
                                }
                                , 1500)
                            }}
                        >
                            時間切れ
                        </Button>
                    </Box>

                    <Accordion 
                        index={accordionIndex}
                        allowToggle="true"
                    >
                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    onClick={()=>{
                                        if (accordionIndex !== 0) {
                                            setAccordionIndex(0)
                                        } else {
                                            setAccordionIndex(-1)
                                        }
                                    }}
                                >
                                    <Center
                                        width="100%"
                                        className="blink"
                                    >
                                       💡 ヒント 💡
                                    </Center>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb="4">
                                {hintSection}
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion>
                </Stack>
            </Box>

            <Modal 
                isOpen={isCorrectOpen} 
                onClose={onCorrectClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <VStack
                            color="blue.400"
                            p="10px 0px 10px 0px"

                        >
                            <Box
                                fontSize="5xl"
                            >
                                正解
                            </Box>

                            <Box
                                color="black"
                                pb="5px"
                            >
                                この単語の理解度を選んで下さい
                            </Box>

                            <Flex
                                justify="space-between"
                                width="100%"
                            >
                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="cyan.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={(e)=> {
                                        if (e.key !== "Enter") {
                                            onCorrectClose()
                                            onSubmit("correct", "easy")
                                        }
                                    }}
                                >
                                    <VStack>
                                        <SlightlySmilingFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            自信あり
                                        </Box>
                                    </VStack>
                                </Button>

                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="blue.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={(e)=> {
                                        if (e.key !== "Enter") {
                                            onCorrectClose()
                                            onSubmit("correct", "good")
                                        }
                                    }}
                                >
                                    <VStack>
                                        <NeutralFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            そこそこ
                                        </Box>
                                    </VStack>
                                </Button>

                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="purple.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={(e)=> {
                                        if (e.key !== "Enter") {
                                            onCorrectClose()
                                            onSubmit("correct", "hard")
                                        }
                                    }}
                                >
                                    <VStack>
                                        <SadButRelievedFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            自信ない
                                        </Box>
                                    </VStack>
                                </Button>

                            </Flex>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={isIncorrectOpen} 
                onClose={onIncorrectClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <Center
                            pt="10px"
                        >
                            <VStack>
                                <Box
                                    color="red.400"
                                    fontSize="5xl"
                                >
                                    不正解😔
                                </Box>
                                <Box
                                    fontSize="md"
                                >
                                    {getCorrectAnswer()}
                                </Box>
                            </VStack>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={isTimeoutOpen} 
                onClose={onTimeoutClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <Center
                            pt="10px"
                        >
                            <VStack>
                                <Box
                                    color="red.400"
                                    fontSize="5xl"
                                >
                                    時間切れ⏱️
                                </Box>
                                <Box
                                    fontSize="md"
                                >
                                    {getCorrectAnswer()}
                                </Box>
                            </VStack>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box
                m="0% 5% 0% 5%"
            >
                <BackButton
                    dispatch={props.dispatch}
                />
            </Box>

        </Stack>
    )
}
  
export default QuizWordAdvance

