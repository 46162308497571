import React, { useState } from "react";
import { 
    useMsal
} from '@azure/msal-react'
import {
    Box,
    Button,
    Text,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    InputGroup
} from "@chakra-ui/react";
import {
    WarningIcon
} from "@chakra-ui/icons"

import { useUserContext } from "../context/userContext"
import useApi from "../hooks/useApi";


const DeleteAccountDialog = (props) => {

    const {
        getUserName,
        clearUserInfo
    } = useUserContext()        

    const {
        deleteAccount
    } = useApi()

    const userName = getUserName()
    const { instance } = useMsal();
    const [userNameMatch, setUserNameMatch] = useState(false)

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    <WarningIcon
                        m="0px 8px 4px 0px"
                        color="red.500"
                        fontSize="30px"
                    />
                    アカウント削除
                </ModalHeader>
                <ModalCloseButton 
                    onClick={() => {
                        props.onClose()
                    }}
                />                
                <ModalBody>
                    <Box
                        fontSize="sm"
                    >
                        <Text>
                            あなたのアカウントと全てのデータをシステムから完全に削除します。<br/>
                            この操作は取り消すことができません。削除されたアカウントとデータは復元することができません。
                        </Text>
                        <Text>
                            削除を行いたい場合は、下のテキストボックスに「表示名」と同じ文字列をタイプしてください。<br/>
                            タイプした文字列が表示名と一致すると [アカウント削除] ボタンがクリックできるようになります。<br/>
                            クリックすると削除処理が開始されます。
                        </Text>
                    </Box>
                    <Box
                        mb="10px"
                    >
                        表示名: {userName}
                    </Box>
                    <Box>
                        <InputGroup>
                            <Input
                                id="userName"
                                autoComplete="off"
                                onChange={(e) => {
                                    setUserNameMatch(e.target.value === userName)
                                }}
                                onPaste={(e) => {
                                    e.preventDefault()
                                }}
                                placeholder="表示名を入力してください"
                            />                        
                        </InputGroup>
                    </Box>


                </ModalBody>
                <ModalFooter
                    justifyContent="flex-start"
                >
                    <HStack>
                        <Button
                            isDisabled={!userNameMatch}
                            colorScheme="red"
                            onClick={async () => {
                                try {
                                    await deleteAccount()
                                    await clearUserInfo()
                                    await instance.logoutRedirect()
                                } catch (error) {
                                    console.log(error)
                                }
                                props.onClose()
                            }}
                        >
                            アカウント削除
                        </Button>
                        <Button
                            colorScheme="gray"
                            onClick={() => {
                                props.onClose()
                            }}
                        >
                            キャンセル
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default DeleteAccountDialog