import React, { useState, useEffect } from "react"
import { 
    Button,
    Box,
    Center
} from "@chakra-ui/react"

import {
    useMsal,
    UnauthenticatedTemplate
} from '@azure/msal-react'

import { 
    loginRequest
} from "../authConfig"

import useSubmit from "../hooks/useSubmit"

const SignInPage = () => {
	/**
	 * useMsal is hook that returns the PublicClientApplication instance,
	 * that tells you what msal is currently doing. For more, visit:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
	 */
	const { instance } = useMsal();

    const { getMsalAuthResponse } = useSubmit()

    const handleRedirect = async () => {
        await instance.loginRedirect({
            ...loginRequest,
            // prompt: "create"
            prompt: "select_account"
        }).catch((error) => console.log(error))
    }

    const [authResponse, setAuthResponse] = useState(null)
    useEffect(() => {
        Promise.resolve()
            .then(() => getMsalAuthResponse())
            .then((response) => {
                setAuthResponse(response)
                return response
            })
            .finally((response) => console.log(`response: ${JSON.stringify(response)}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <UnauthenticatedTemplate>
                <Box
                    width="100%"
                    height="100%"
                >
                    <Center 
                        height="100%"
                    >
                        <Button
                            id="signInButton"
                            onClick={() => handleRedirect()}
                            colorScheme="blue"
                            // display="None"
                        >
                            サインイン
                        </Button>
                    </Center>
                </Box>
            </UnauthenticatedTemplate>
            {
                authResponse ?
                    <Box id="authResponse">
                        {"{"}
                        "accessToken":"{authResponse.accessToken}",
                        "expiredAt":"{authResponse.expiresOn}"
                        {"}"}
                    </Box>
                    :
                    <></>
            }
        </>
    )
}

export default SignInPage