import React from "react";
import {
    Button,
    Center,
    Box,
    Heading,
    HStack,
    Flex,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    useDisclosure    
} from '@chakra-ui/react'
import {
    CloseIcon
} from '@chakra-ui/icons'

import { IoInformationCircle } from "react-icons/io5";

const ContentCard = (props) => {

    const { 
        isOpen,
        onOpen,
        onClose
    } = useDisclosure()      

    const handleClick = () => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // })
        // setTimeout(() => {
            onOpen()
        // }, 500);        
    }
        
       
    return (
        <>
            <Modal 
                isOpen={isOpen} 
                onClose={() => onClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    width="80%"
                    maxW="650px"
                    maxHeight="90vh" // max hight of the modal
                    my="5vh" // top and bottom margins
                >
                    <ModalHeader>
                        {props.title}
                    </ModalHeader>
                    <ModalCloseButton>
                        <CloseIcon
                            onClick={()=>onClose()}
                        />
                    </ModalCloseButton>
                    <ModalBody
                        overflowY="auto"
                    >
                        {props.details}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={()=>onClose()}
                        >
                            閉じる
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                onClick={handleClick}
                w={[350, 450, 650]}
                boxShadow="md"
                m="0px 0px 12px 0px"
            >                
            <CardBody
                width="100%"
                p="5px"
            >
                <Heading
                    m="0px 0px 4px 8px"
                    size='md'
                    color="orange.300"
                >
                    <Flex align="center">
                        <IoInformationCircle style={{ fontSize: "1.8em", margin: "4px 4px 0px 0px" }} />
                        <span>{props.title}</span>
                    </Flex>
                </Heading>
                <HStack>
                    <Box
                        width="60px"
                        display="flex"
                        flexDirection="column"
                    >
                        {props.cardImage}    
                    </Box>
                    <Box
                        flex="1"
                    >
                        <Center
                            width="100%"
                        >
                            <Box
                                m="4px 4px 0px 4px"
                                fontSize="sm"
                            >
                                {props.summary}
                            </Box>
                        </Center>
                        <Flex>
                            <Spacer/>
                            <Button 
                                onClick={handleClick}
                                bg="orange.300"
                                color="white"
                                border="3px solid #F6AD55"
                                _hover={{ bg: "white", color: "orange.300", border: "3px solid #F6AD55" }}
                                height="28px"
                                fontSize="sm"
                                m="4px 20px 8px 4px"
                            >
                                詳しくはこちら
                            </Button>
                        </Flex>
                    </Box>
                </HStack>

            </CardBody>
            </Card>   
        </>
    )
}

export default ContentCard


