import React, { useState, useRef, useEffect }  from "react";
import {
    Box,
    Button,
    Center,
    Text,
    HStack,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    VStack,
    Flex,
    Spacer,
    Card,
    CardBody,
    Divider,
    useDisclosure,
} from "@chakra-ui/react";

import useApi from "../../hooks/useApi"
import "../../App.css";
import {useUserContext} from "../../context/userContext"
import ContentHeading from "../ContentHeading"
import Timer from "../Timer"
import BackButton from "../BackButton";
import HeaderComponent from "../HeaderComponent";

import { ReactComponent as SlightlySmilingFaceFlat } from "../../assets/icons/slightly_smiling_face_flat.svg"
import { ReactComponent as NeutralFaceFlat } from "../../assets/icons/neutral_face_flat.svg"
import { ReactComponent as SadButRelievedFaceFlat } from "../../assets/icons/sad_but_relieved_face_flat.svg"


const QuizWordNormal = (props) => {
    const maxElapseSeconds = 15
    const warningSeconds = 5

    const {
        getFsrsRetention             
    } = useUserContext()

    const [startTime, setStartTime] = useState(new Date())
    const [elapsedSeconds, setElapsedSeconds] = useState(maxElapseSeconds)

    const {commitActivity} = useApi()

    const displayNameRef = useRef("")
    const onSubmit = async (answerStatus, rating) => {
        const displayName = props.params.displayName

        if (displayNameRef.current !== displayName) {
            displayNameRef.current = displayName
            const wordId = props.params.wordId
            const cId = props.params.cId
            const meaning = props.params.meaning
            const quizHistory = props.params.quizHistory
            await commitActivity(
                wordId,
                cId,
                props.params.activity,
                displayName,
                meaning,
                {
                    "elapsedSeconds": elapsedSeconds,
                    answerStatus,
                    rating,
                    quizHistory,
                    "fsrsRetention": getFsrsRetention()
                }
            )

            window.scrollTo(0, 0)
            props.dispatch({
                type: "quizAnswer",
                data: {
                    previous: {},
                    current: {
                        meaning,
                        cId,
                        wordId,
                        displayName,
                        isCorrect: answerStatus === "correct",
                    }
                }
            })
            setTimerFlag(`restart_${Math.random()}`)
        }
    }

    ///////////////////////////////////////////////
    // timer location
    
    const [timerLocation, setTimerLocation] = useState(getTimerLocation())
    function getTimerLocation() {
        const bottom = 90
        const leftOffset = 220
        const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        const panelWidth = Math.min(viewportWidth, 810)
        let left = 0
        if (viewportWidth > panelWidth) {
            left = (viewportWidth - panelWidth) / 2 + panelWidth - leftOffset
        } else {
            left = viewportWidth - leftOffset
        }
        return {left, bottom}
    }
    window.addEventListener('load', (e) => {
        setTimerLocation(getTimerLocation(document))
    })
    window.addEventListener('resize', (e) => {
        setTimerLocation(getTimerLocation(document))
    })

    ///////////////////////////////////////////////    

    const { 
        isOpen: isCorrectOpen,
        onOpen: onCorrectOpen,
        onClose: onCorrectClose
    } = useDisclosure()
    const { 
        isOpen: isIncorrectOpen, 
        onOpen: onIncorrectOpen, 
        onClose: onIncorrectClose
    } = useDisclosure()
    const { 
        isOpen: isTimeoutOpen, 
        onOpen: onTimeoutOpen, 
        onClose: onTimeoutClose
    } = useDisclosure()


    const [timerFlag, setTimerFlag] = useState("")
    const timeoutRef = useRef()

    const quizIndex = ["1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣", "🔟"]
    const getCorrectAnswer = () => {
        return `正解: ${quizIndex[props.params.correctMeaningIndex]} ${props.params.meaning}`
    }

    ////////////////////////////////////////////////////
    // setStartTime
    useEffect(() => {
        setStartTime(new Date())
    }, [props.params.wordId, setElapsedSeconds])    

    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"
        >
            <Flex
                height="28px"
                width="200px"
                position="fixed"
                zIndex="200"
                left={`${timerLocation.left}px`}
                bottom={`${timerLocation.bottom}px`}
            >
                <Spacer />
                <Timer
                    timerSeconds={maxElapseSeconds}
                    warningSeconds={warningSeconds}
                    timerFlag={timerFlag}
                    timeoutFunc={() => {
                        if (!isCorrectOpen && !isIncorrectOpen) {
                            timeoutRef.current.click()
                        }
                    }}
                />
            </Flex>

            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <>
                        <ContentHeading 
                            title={`英単語クイズ: ${props.params.quizIndex + 1} / ${props.params.quizCount} 問目`}
                        />
                        <Box
                            m="6px 0px 0px 16px"
                        >
                            次の英単語の意味を選択してください
                        </Box>
                    </>
                }
            />                 

            <Box 
                rounded="md"
                border="0px 10px 10px 0px"
                borderColor="blue.100"
                padding={"0px 10px 30px 10px"}
                margin="0% 5% 0% 5%"
            >
                <Stack>
                    <Box>
                        <Text 
                            fontSize="6xl"
                        >
                            {props.params.displayName}
                        </Text>
                    </Box>
                    <Box
                        mb="24px"
                    >
                        {
                            props.params.meaningOptions.map((meaningOption, meaningIndex) => 
                                <React.Fragment
                                    key={meaningIndex}
                                >
                                    {
                                        props.params.meaningOptions.length - 2 <= meaningIndex ?
                                            <Divider
                                                w={[350, 450, 650]}
                                                m="20px 0px 20px 0px"
                                            />
                                            :
                                            <></>
                                    }

                                    <Card
                                        direction={{ base: 'column', sm: 'row' }}
                                        overflow='hidden'
                                        variant='outline'
                                        onClick={() => {
                                            setElapsedSeconds(Math.round((new Date() - startTime) / 1000),)
                                            if (meaningOption.status === "correct") {
                                                onCorrectOpen()
                                                setTimerFlag(`pause_${Math.random()}`)
                                                // setTimeout(() => {
                                                //     onCorrectClose()
                                                //     onSubmit("correct", "good")
                                                // }
                                                // , 1500)
                                            } else if (meaningOption.status === "incorrect") {
                                                onIncorrectOpen()
                                                setTimeout(() => {
                                                    onIncorrectClose()
                                                    onSubmit("incorrect", "again")
                                                }
                                                , 1500)
                                            } else {
                                                onTimeoutOpen()
                                                setTimeout(() => {
                                                    onTimeoutClose()
                                                    onSubmit("incorrect", "again")
                                                }
                                                , 1500)
                                            }                        
                                        }}
                                        w={[350, 450, 650]}
                                        boxShadow="md"
                                        mb="10px"
                                        ref={props.params.meaningOptions.length - 1 === meaningIndex ? timeoutRef : null}
                                        style={props.params.meaningOptions.length - 1 === meaningIndex ? { display: 'none' } : {}}
                                        _hover={{ bg: "blue.50" }}
                                    >
                                        <Box>
                                            <HStack>
                                                <Box
                                                    ml="5px"
                                                    fontSize="xl"
                                                >
                                                    {quizIndex[meaningIndex]}
                                                </Box>
                                                <CardBody
                                                    p="5px"
                                                >
                                                    {meaningOption.meaning}
                                                </CardBody>
                                            </HStack>
                                        </Box>
                                    </Card>
                                </React.Fragment>
                            )
                        }   

                    </Box>

                </Stack>
            </Box>

            <Modal 
                isOpen={isCorrectOpen} 
                onClose={onCorrectClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <VStack
                            color="blue.400"
                            p="10px 0px 10px 0px"

                        >
                            <Box
                                fontSize="5xl"
                            >
                                正解
                            </Box>

                            <Box
                                color="black"
                                pb="5px"
                            >
                                この単語の理解度を選んで下さい
                            </Box>

                            <Flex
                                justify="space-between"
                                width="100%"
                            >
                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="cyan.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={()=> {
                                        onCorrectClose()
                                        onSubmit("correct", "easy")
                                    }}
                                >
                                    <VStack>
                                        <SlightlySmilingFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            自信あり
                                        </Box>
                                        </VStack>
                                </Button>

                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="blue.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={()=> {
                                        onCorrectClose()
                                        onSubmit("correct", "good")
                                    }}
                                >
                                    <VStack>
                                        <NeutralFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            そこそこ
                                        </Box>
                                        </VStack>
                                </Button>

                                <Button
                                    height="90px"
                                    width="90px"
                                    bgColor="white"
                                    borderColor="purple.500"
                                    color="blue.800"
                                    borderWidth="4px"
                                    onClick={()=> {
                                        onCorrectClose()
                                        onSubmit("correct", "hard")
                                    }}
                                >
                                    <VStack>
                                        <SadButRelievedFaceFlat
                                            width="48px"
                                            height="48px"
                                        />
                                        <Box>
                                            自信ない
                                        </Box>
                                        </VStack>
                                </Button>

                            </Flex>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={isIncorrectOpen} 
                onClose={onIncorrectClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <Center
                            pt="10px"
                        >
                            <VStack>
                                <Box
                                    color="red.400"
                                    fontSize="5xl"
                                >
                                    不正解😔
                                </Box>
                                <Box
                                    fontSize="md"
                                >
                                    {getCorrectAnswer()}
                                </Box>
                            </VStack>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={isTimeoutOpen} 
                onClose={onTimeoutClose}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalBody>
                        <Center
                            pt="10px"
                        >
                            <VStack>
                                <Box
                                    color="red.400"
                                    fontSize="5xl"
                                >
                                    時間切れ⏱️
                                </Box>
                                <Box
                                    fontSize="md"
                                >
                                    {getCorrectAnswer()}
                                </Box>
                            </VStack>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box
                m="0% 5% 0% 5%"
            >
                <BackButton
                    dispatch={props.dispatch}
                />
            </Box>

        </Stack>
    )
}
  
export default QuizWordNormal


