
import {
    Box,
    Flex,
    Heading,
    Spacer,
} from "@chakra-ui/react";

const ContentHeading = (props) => 
    <Flex
        height="16px"
    >
        <Box>
            <Heading
                as="h1"
                size="xs"
                m="0px"
            >
                {props.title}
            </Heading>
        </Box>
        <Spacer/>
    </Flex>

export default ContentHeading