import { createContext, useContext, useState } from "react";
import { getRandomInt } from "../CommonFunction"

const FullCoverSpinnerContext = createContext(undefined)

export const FullCoverSpinnerProvider = ({ children }) => {

    const [idSet, setIdSet] = useState(new Set())
    const [startTime, setStarttime] = useState(new Date())
    const maxBusyPeriod = 30 * 1000

    return (
        <FullCoverSpinnerContext.Provider
            value={{
                idSet,
                openRequest: () => {
                    if (idSet.size === 0) {
                        setStarttime(new Date())
                    }
                    const id = getRandomInt()
                    idSet.add(id)
                    setIdSet(new Set(idSet))
                    return id
                },
                closeRequest: (id) => {
                    idSet.delete(id)
                    if (idSet.size === 0) {
                        setStarttime(new Date())
                    }
                    setIdSet(new Set(idSet))
                },
                isBusy: () => {
                    return idSet.size > 0
                },
                isTimeout: () => {
                    if (idSet.size > 0) {
                        if ((new Date()).getTime() - startTime.getTime() > maxBusyPeriod) {
                            setIdSet(new Set())
                            setStarttime(new Date())
                            return true
                        }
                    } 
                    
                    return false
                }
            }}
        >
            {children}
        </FullCoverSpinnerContext.Provider>
    )
}

export const useFullCoverSpinnerContext = () => useContext(FullCoverSpinnerContext);