import React from "react";
import {
    Box,
    Center,
    HStack,
    Spacer,
    Stack,
} from "@chakra-ui/react";
import {
    AddIcon,
    EditIcon,
    DeleteIcon
} from '@chakra-ui/icons'
import {
    Splide,
    SplideSlide
} from "@splidejs/react-splide"
import '@splidejs/react-splide/css';

import SoundEffect from "../SoundEffect";

import "../../App.css";
import {
    getSentenceMediaUrl,
    getIndexesById
} from "../../WordDataFunction"
import ImageElement from "../ImageElement";
import useApi from "../../hooks/useApi";


const SentenceList = (props) => {

    const {
        getSentenceImage
    } = useApi()

    const sentenceAudio = (wordData, meaningIndex, sentenceIndex) => {
        const url = getSentenceMediaUrl(
            wordData,
            "audio",
            props.displayWordParams.sases,
            meaningIndex,
            sentenceIndex)
        
        return (
            url ?
                <SoundEffect
                    key={url}
                    url={url}
                    size={24}
                /> 
                :
                <></>
        )
    }    

    const sentenceList = []

    props.sentences.forEach(async (sentence, sentenceIndex) => {
        let idMap = {
            ...props.idMap,
            sentenceId: sentence.sentenceId
        }

        let image = null
        if (sentence.origin === "user") {
            if (sentence.sentenceId in props.userSentenceImages) {
                image = props.userSentenceImages[sentence.sentenceId]
            } else if (sentence.hasImage) {
                image = await getSentenceImage(sentence.sentenceId)
                if (image !== null) {
                    props.setUserSentenceImages(prevUserSentenceImage => ({
                        ...prevUserSentenceImage,
                        [sentence.sentenceId]: image
                    }))
                }
            }
        } else if (sentence.origin === "system") {
            image = getSentenceMediaUrl(
                props.displayWordParams.wordData,
                "image",
                props.displayWordParams.sases,
                props.meaningIndex,
                sentenceIndex
            )
        }

        sentenceList.push(
            <SplideSlide key={`${props.posIndex}_${props.meaningIndex}_${sentenceIndex}`}>
                <Box
                    border="1px"
                    borderColor="blue.100"
                    p="2px"
                    m="2px"
                >
                    <Box
                        width="100%"
                    >
                        <Stack
                            width="100%"
                        >
                            <HStack
                                mb="6px"
                            >
                                <Center
                                    bgColor="blue.400"
                                    color="white"
                                    fontSize="xs"
                                    width="36px"
                                    height="24px"
                                >
                                    {`例${sentenceIndex + 1}`}
                                </Center>
                                <Box>
                                    {sentenceAudio(
                                        props.displayWordParams.wordData,
                                        props.meaningIndex,
                                        sentenceIndex
                                    )}
                                </Box>
                                <Spacer/>
                                {
                                    props.displayWordParams.mode === "readyToEditWordElement" ?
                                        <>
                                            <AddIcon
                                                onClick={() => {
                                                    if (!props.displayWordParams.exceedMaxUserSentenceCount[props.idMap.meaningId]) {
                                                        props.dispatch({
                                                            type: "displayWordEditDialogOpened",
                                                            data: {
                                                                previous:{
                                                                    search: props.searchParams
                                                                },
                                                                current: {
                                                                    wordData: props.displayWordParams.wordData,
                                                                    editDialogParams: {
                                                                        type: "sentenceAdd",
                                                                        header: `[例${props.sentences.length + 1}] として、例文、例文の意味、を追加します"`,
                                                                        displayName: props.displayWordParams.displayName,
                                                                        pronunciation: props.displayWordParams.pronunciation,
                                                                        pos: props.pos,
                                                                        inusedPoses: props.displayWordParams.inusedPoses,
                                                                        meaning: props.meaning.meaning,
                                                                        sentence: "",
                                                                        sentenceMeaning: "",                                                        
                                                                        idMap: {
                                                                            ...props.idMap,
                                                                            sentenceId: crypto.randomUUID()
                                                                        },
                                                                        isOpen: true
                                                                    },
                                                                    isEditted: props.displayWordParams.isEditted
                                                                }
                                                            }
                                                        })
                                                    }
                                                }}
                                                color={
                                                    props.displayWordParams.exceedMaxUserSentenceCount[props.idMap.meaningId] ?
                                                        "gray.300"
                                                        :
                                                        "black"
                                                }                                                
                                            />
                                            {
                                                sentence.origin === "user" ?
                                                    <>
                                                        <EditIcon
                                                            onClick={() => {
                                                                props.dispatch({
                                                                    type: "displayWordEditDialogOpened",
                                                                    data: {
                                                                        previous:{
                                                                            search: props.searchParams
                                                                        },
                                                                        current: {
                                                                            wordData: props.displayWordParams.wordData,
                                                                            editDialogParams: {
                                                                                type: "sentenceEdit",
                                                                                header: `[例${sentenceIndex + 1}] の例文、例文の意味を変更します`,
                                                                                displayName: props.displayWordParams.displayName,
                                                                                pronunciation: props.displayWordParams.pronunciation,
                                                                                pos: props.pos,
                                                                                meaning: props.meaning.meaning,
                                                                                sentence: sentence.sentence,
                                                                                sentenceMeaning: sentence.meaning,
                                                                                idMap,
                                                                                isOpen: true
                                                                            },
                                                                            isEditted: props.displayWordParams.isEditted
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        {
                                                            props.sentences.length > 1 ?
                                                                <DeleteIcon
                                                                    onClick={() => {
                                                                        props.dispatch({
                                                                            type: "displayWordEditDialogOpened",
                                                                            data: {
                                                                                previous:{
                                                                                    search: props.searchParams
                                                                                },
                                                                                current: {
                                                                                    wordData: props.displayWordParams.wordData,
                                                                                    editDialogParams: {
                                                                                        type: "sentenceDelete",
                                                                                        header: `例文：[例${sentenceIndex + 1}] を削除します。この例文に設定された全ての情報が削除されます。※削除すると復元することはできません`,
                                                                                        sentence: sentence.sentence,
                                                                                        sentenceMeaning: sentence.meaning,
                                                                                        idMap,
                                                                                        isOpen: true
                                                                                    }
                                                                                },
                                                                                isEditted: props.displayWordParams.isEditted
                                                                            }
                                                                        })
                                                                    }}                                                
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </HStack>
                            <Box>
                                <Box
                                    mb="10px"
                                >
                                    {sentence.sentence}
                                </Box>
                                <Box
                                    mb="10px"
                                >
                                    {sentence.meaning}
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                    <Center
                        width="100%"
                    >
                        <Box
                            maxW="256px"
                            maxH="256px"
                            mb="7px"
                        >
                            <ImageElement
                                url={image}
                                maxW="200px"
                                maxH="200px"
                            />
                        </Box>
                    </Center>
                </Box>
            </SplideSlide>
        )
    })

    return (
        sentenceList.length > 0 ?
            <>
                <Splide
                    label={`splide_sentence_${props.posIndex}_${props.meaningIndex}`}
                    options={
                        {
                            rewind:true,
                            start: props.displayWordParams.editDialogParams.type === "sentenceAdd" &&
                                getIndexesById(props.displayWordParams.wordData, props.idMap.meaningId)[0] === props.meaningIndex ?
                                    props.sentences.length
                                    :
                                    0
                        } 
                    }
                >
                    {sentenceList}
                </Splide>
                
            </>
            :
            <></>
    )
}


export default SentenceList