import {useState} from "react"
import {useMsal} from '@azure/msal-react'
import {apiRequest, authRequest} from "../authConfig"
import {
    convertDatesToIso8601Jst,
    mergeObjects
} from "../CommonFunction"
import { useAuthContext } from "../context/authContext"
import userAsyncLocalStorage from "../hooks/useAsyncLocalStorage"

const useSubmit = () => {
    
    const {
        instance
    } = useMsal()
    
    const {
        switchToSignOut,
        validateAccessToken
    } = useAuthContext()

    const {
        setAuthInfo,
        getAuthInfo
    } = userAsyncLocalStorage()
    
    const [requestIds, setRequestIds] = useState({})
    const timeoutInMsec = 10000
    const maxRetryCount = 6


    const getBearerToken = async () => {

        const authInfo = await getAuthInfo()
        if (Object.keys(authInfo).length > 0) {
            if (await validateAccessToken(authInfo)) {
                return {
                    "accessToken": authInfo.azureInfo.accessToken,
                    "xUserInfo": authInfo.azureInfo.xUserInfo
                }
            } else if (authInfo.issuerInfo.refreshToken) {
                // get accessToken using refreshToken
                const response = await submit(
                    authRequest.url,
                    {
                        requestType: "refresh",
                        issuer: authInfo.issuerInfo.issuer,
                        refreshToken: authInfo.issuerInfo.refreshToken
                    },
                    null,
                    false
                )

                if (response.status === "success") {
                    const updateAuthInfo = mergeObjects(authInfo, response.result)
                    await setAuthInfo(updateAuthInfo)

                    if (response.result.azureInfo.accessToken) {
                        return {
                            "accessToken": response.result.azureInfo.accessToken,
                            "xUserInfo": response.result.azureInfo.xUserInfo
                        }
                    } else {
                        // TBD: promot the user to sign in again
                        switchToSignOut()
                    }
                }
            }
        }

        return {
            "accessToken": "",
            "xUserInfo": ""
        }

    }




    const getMsalAuthResponse = async () => {
        await instance.initialize();
    
        return new Promise((resolve, reject) => {
            let count = 0
            const intervalId = setInterval(async () => {
                const account = instance.getActiveAccount()
    
                if (account) {
                    clearInterval(intervalId)
                    try {
                        const response = await instance.acquireTokenSilent({
                            ...apiRequest,
                            account
                        });
                        resolve(convertDatesToIso8601Jst(response))
                    } catch (error) {
                        reject(error)
                    }
                } else if (++count > 50) {
                    clearInterval(intervalId)
                    resolve(null)
                }
            }, 100)
        })
        
        

            // await instance.initialize()

            // let response = null
            // const id = setInterval(async () => {
            //     let count = 0
            //     const account = instance.getActiveAccount()
            //     if (account) {
            //         clearInterval(id)
            //         response = await instance.acquireTokenSilent({
            //             ...apiRequest,
            //             account
            //         })
            //         return convertDatesToIso8601Jst(response)
            //     } else if (++count > 50) {
            //         clearInterval(id)
            //     }
            // }, 100)

            // return response

        // return Promise.resolve()
        //     .then(() => instance.initialize())
        //     .then(() => {
        //         let response = null
        //         const id = setInterval(async () => {
        //             let count = 0
        //             const account = instance.getActiveAccount()
        //             if (account) {
        //                 clearInterval(id)
        //                 response = await instance.acquireTokenSilent({
        //                     ...apiRequest,
        //                     account
        //                 })
        //                 return convertDatesToIso8601Jst(response)
        //             } else if (++count > 50) {
        //                 clearInterval(id)
        //                 return null
        //             }
        //         }, 100)
        //     })

                // return Promise.resolve()
        //     .then(() => instance.initialize())
        //     .then(() => {
        //         let response = null
        //         setTimeout(async() => {
        //             const account = instance.getActiveAccount()
        //             if (account) {
        //                 response = await instance.acquireTokenSilent({
        //                     ...apiRequest,
        //                     account
        //                 })
        //                 return convertDatesToIso8601Jst(response)
        //             }
        //             return null
        //         }, 3000)
        //     })

        // console.log("instance:" + JSON.stringify(instance))
        // await instance.initialize()
        // const account = instance.getActiveAccount()
        
        // if (account) {
        //     const response = await instance.acquireTokenSilent({
        //         ...apiRequest,
        //         account
        //     })
        
        //     return convertDatesToIso8601Jst(response)
        // }

        // return null
    }    
  
    const submit = async (url, requestBody, requestId=null, needBearerToken=true, headers={}) => {

        let token = null
        if (needBearerToken) {
            token = await getBearerToken(false)
        }

        let error = {}
        let jresponse = {}

        if (needBearerToken
            && !token.accessToken) {
            // error
        } else {
            if (requestId !== null) {
                setRequestIds({
                    ...requestIds,
                    [requestId]: true
                })
            }

            for (let retry = 0; retry < maxRetryCount; retry++) {

                const controller = new AbortController();
                const timeout = setTimeout(() => {
                    controller.abort()
                }, timeoutInMsec)

                try {
                    if (Object.keys(headers).length === 0) {
                        headers = {
                            "Content-Type": "application/json"
                        }
                    }

                    if (needBearerToken) {
                        headers["Authorization"] = `Bearer ${token.accessToken}`
                    }

                    if (needBearerToken) {
                        headers["X-UserInfo"] = token.xUserInfo
                    }

                    const response = await fetch(url, {
                        method: "POST",
                        headers,
                        body: JSON.stringify(requestBody),
                        signal: controller.signal,
                        // mode: "cors",
                        // credentials: "include"
                    })
                    if (response.ok) {
                        if (requestId !== null) {
                            setRequestIds({
                                ...requestIds,
                                [requestId]: false
                            })
                        }
                        clearTimeout(timeout)
                        jresponse = await response.json()
                        return jresponse
                    } else {
                        throw new Error(`statsCode: ${response?.status}, message: ${response?.statusText}`);
                    }
                } catch (e) {
                    error = e
                } finally {
                    clearTimeout(timeout)
                    if (requestId !== null) {
                        setRequestIds({
                            ...requestIds,
                            [requestId]: false
                        })
                    }
                }
            }
        }

        jresponse = {
            status: "fail",
            statusCode: error && "statusCode" in error ? error.statusCode : -1,
            message: error && "message" in error ? error.message : "something went wrong."
        }
        return jresponse
    }

    const isLoading = (requestId) => {
        return requestId in requestIds ? requestIds[requestId] : null
    }

    return {
        apiEndPoint: apiRequest.url,
        authEndPoint: process.env.REACT_APP_AUTH_TOKENS_URL,
        isLoading,
        submit,
        getMsalAuthResponse,
        getBearerToken
    }
}
  
export default useSubmit;
