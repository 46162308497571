import React from "react";
import {
    Button
} from "@chakra-ui/react";
import {
    ArrowBackIcon
} from "@chakra-ui/icons"

const BackButton = (props) => {

return (
    <Button
        leftIcon={<ArrowBackIcon />}
        colorScheme="blue"
        variant="outline"
        width="80px"
        onClick={() => props.dispatch({type:"browserBack"})}
    >
        戻る
    </Button>
    )
}

export default BackButton