import {
    getValueFromWord,
    getSentenceMediaUrl
} from "../../WordDataFunction"
import {
    randomPickupItems,
    removeLinkTags
} from "../../CommonFunction"

export function buildQuizWordProps(data, sases) {

    const activity = data.isRandom ? "quizRandom" : "quiz"
    const quizIndex = data.answers.length
    const quizCount = data.quizWords.length

    const wordData = data.quizWords[quizIndex]
    
    const wordId = getValueFromWord(wordData, "wordId")
    const cId = getValueFromWord(wordData, "cId")
    const displayName = getValueFromWord(wordData, "displayName")

    const quizSentence = getValueFromWord(
        wordData,
        "quizSentence",
        0,
        0
    )
    const sentenceImageUrl = getSentenceMediaUrl(
        wordData,
        "image",
        sases,
        0,
        0
    )

    ///////////////////////////////////////
    // set up meaningOptions

    const meaning = removeLinkTags(getValueFromWord(
        wordData,
        "meaning",
        0
    ))

    let meaningOptions = [
        {meaning, status: "correct"}
    ]
    const meaningCandidates = getValueFromWord(wordData, "meaningCandidates")
    if (meaningCandidates) {
        meaningCandidates.forEach((meaning) => {
            meaningOptions.push({meaning, status: "incorrect"})
        })
    }
    meaningOptions = randomPickupItems(meaningOptions, meaningOptions.length)
    meaningOptions.push({meaning: "わかりません", status: "incorrect"})
    meaningOptions.push({meaning: "時間切れ", status: "timeout"})

    let correctMeaningIndex = -1
    for (let i = 0; i < meaningOptions.length; ++i) {
        if (meaningOptions[i].status === "correct") {
            correctMeaningIndex = i
            break
        }
    }

    ///////////////////////////////////////

    const quizHistory = getValueFromWord(wordData, "quizHistory")

    ///////////////////////////////////////

    return {
        activity,
        quizIndex,
        quizCount,
        wordId,
        cId,
        displayName,
        meaning,
        quizSentence,
        sentenceImageUrl,
        meaningOptions,
        quizHistory,
        correctMeaningIndex        
    }    
}