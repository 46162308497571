import React from "react";
import {
    Box,
    HStack,
    Spacer,
    Stack
} from "@chakra-ui/react";
import '@splidejs/react-splide/css';

import "../../App.css";
import {
    posEtoJ,
    getWordMediaUrls
} from "../../WordDataFunction"
import MeaningList from "./MeaningList";
import SoundEffect from "../SoundEffect"

const PosList = (props)  => {
  
    const wordAudioList = 
        getWordMediaUrls(props.displayWordParams.wordData, "audio", props.displayWordParams.sases).map((url) =>
        <SoundEffect
            key={url}
            url={url}
            size={36}
        />
    )

    const posList = []

    if (props.displayWordParams.wordData.meanings) {
        const meaningMap = {}

        props.displayWordParams.wordData.meanings.forEach((meaning) => {
            const pos = meaning.pos
            if (!(pos in meaningMap)) {
                meaningMap[pos] = []
            }
            meaningMap[pos].push(meaning)
        })

        let meaningStartIndex = 0
        Object.keys(meaningMap).forEach((pos, posIndex) => {
            posList.push(
                <Box
                    key={posIndex}
                    border="1px"
                    borderColor="blue.100"
                    m="2px"
                    p="2px"
                >
                    <HStack>
                        <Box
                            bgColor="blue.600"
                            fontSize="2xl"
                            color="white"
                            p="0px 4px 0px 4px"
                        >
                            {posEtoJ(pos)}
                        </Box>
                        {
                            posIndex === 0 ?
                                <>
                                    <Box
                                        pl="4px"
                                    >
                                        {props.displayWordParams.pronunciation}
                                    </Box>
                                    <HStack spacing={2}>
                                        {wordAudioList}
                                    </HStack>
                                </>
                                :
                                <></>
                        }
                        <Spacer/>
                    </HStack>
                    <MeaningList
                        displayWordParams={props.displayWordParams}
                        searchParams={props.searchParams}
                        pos={pos}
                        posIndex={posIndex}
                        meanings={meaningMap[pos]}
                        meaningStartIndex={meaningStartIndex}
                        dispatch={props.dispatch}
                        userSentenceImages={props.userSentenceImages}
                        setUserSentenceImages={props.setUserSentenceImages}
                        imageRepo={props.imageRepo}
                    />
                </Box>
            )
            meaningStartIndex += meaningMap[pos].length
        })
    }

    return (
        <Stack
            mb="15px"
        >
            {posList}
        </Stack>
    )        
}

export default PosList