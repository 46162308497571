import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    HStack,
    Stack,
    Flex,
    Text,
    Wrap,
    WrapItem,
    Center,
    Button,
    useRadioGroup,
    IconButton
} from "@chakra-ui/react";

import {
    ArrowUpIcon,
    ArrowDownIcon
} from "@chakra-ui/icons"

import { debounce } from "lodash";

import {
    Splide,
    SplideSlide
} from "@splidejs/react-splide"
import '@splidejs/react-splide/css';


import "../../App.css";
import useApi from "../../hooks/useApi";
import { 
    getValueFromWord,
    getWordMediaUrls
} from "../../WordDataFunction";
import { 
    convertToPercentage,
    parseDateTimeStringToJaTime,
    parseDateTimeStringToJaShortDate,
    getSases
} from "../../CommonFunction"
import BackButton from "../BackButton";
import RadioCard from "../RadioCard";
import { useUserContext } from "../../context/userContext"
import WordSnippetDialog from "../WordSnippetDialog";
import HeaderComponent from "../HeaderComponent"
import { useFullCoverSpinnerContext } from "../../context/fullCoverSpinnerContext"

const WordList = (props) => {

    const itemHeight = 42

    const {
        getWordList
    } = useApi()

    const {
        getDaysUntilExpire,
        getUserName
    } = useUserContext()

    const {
        openRequest,
        closeRequest
    } = useFullCoverSpinnerContext()

    const [dataRepo, setDataRepo] = useState({})
    const [viewSettings, setViewSettings] = useState(
        {
            "filterValue": "",
            "sortOrder": "",
            "isFlashCardMode": false,
            "startIndex": 0
        }
    )
    const [selectedWord, setSelectedWord] = useState({
        index: - 1
    })

    const splideRef = useRef()

    useEffect(() => {
        if (props?.dataRepo) {
            setDataRepo({...props.dataRepo})
        }
        if (props?.viewSettings) {
            setViewSettings({...props.viewSettings})
            slideIndexRef.current = props.viewSettings.startIndex
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    ///////////////////////////////////////////////////
    // filter    
    const filterOptions = [
        "startsWith",
        "lastStudiedTime",
        "quizAccuracy",
        "registerTime",
        "starred"
    ]
    const filterLabels = (sortOrder) => {
        switch(sortOrder) {
            case "startsWith":
                return "アルファベット毎"
            case "quizAccuracy":
                return "正解率順"
            case "registerTime":
                return "登録日順"
            case "lastStudiedTime":
                return "最終学習日順"
            case "starred":
                return "☆付き"
                default:
                return "　　"
        }
    }

    const handleFilterChange = async (filterValue) => {
        if (viewSettings.filterValue !== filterValue) {
            let sortOrder = filterValue
            switch(filterValue) {
                case "quizAccuracy":
                    sortOrder += "Asc"
                    break
                case "registerTime":
                case "lastStudiedTime":
                    sortOrder += "Desc"
                    break
                case "starred":
                    sortOrder = "starred"
                    break
                case "startsWith":
                default:
                    sortOrder = ""
                    break
            }

            if (sortOrder && !(sortOrder in dataRepo)) {
                let requestId
                try {
                    requestId = openRequest()
                    const items = await getWordList(sortOrder, 0)
    
                    if (Array.isArray(items)) {
                        dataRepo[sortOrder] = {
                            items,
                            isComplete: items.length === 0
                        }
                        setDataRepo({
                            ...dataRepo
                        })
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    closeRequest(requestId)
                }
            }

            setViewSettings({
                ...viewSettings,
                "filterValue": filterValue,
                "sortOrder": sortOrder,
                "isFlashCardMode": false
            })

            setSelectedWord({
                ...selectedWord,
                index: -1
            })
        }
    }

    const ReverseOrderButton = () => {
        return (
            <IconButton
                m="0px 0px 4px 4px"
                size="xs"
                icon={
                    viewSettings.sortOrder.endsWith("Asc") ?
                        <ArrowUpIcon/>
                        :
                        viewSettings.sortOrder.endsWith("Desc") ?
                            <ArrowDownIcon/>
                            :
                            <></>
                }
                onClick={async () => {
                    let sortOrder = ""
                    if (viewSettings.sortOrder.endsWith("Asc")) {
                        sortOrder = `${viewSettings.filterValue}Desc`
                    } else if (viewSettings.sortOrder.endsWith("Desc")) {
                        sortOrder = `${viewSettings.filterValue}Asc`
                    }

                    if (sortOrder) {
                        if (!(sortOrder in dataRepo)) {
                            let requestId
                            try {
                                requestId = openRequest()
                                const items = await getWordList(sortOrder, 0)

                                if (items) {
                                    if (Array.isArray(items)) {
                                        dataRepo[sortOrder] = {
                                            items,
                                            isComplete: items.length === 0
                                        }
                                        setDataRepo({
                                            ...dataRepo
                                        })                   
                                    }             
                                }
                            } catch (error) {
                                console.log(error)
                            } finally {
                                closeRequest(requestId)
                            }
                        }

                        setViewSettings({
                            ...viewSettings,
                            "sortOrder": sortOrder
                        })                            
                    }
                }}
            >
            </IconButton>
        )
    }


    const { value, getRootProps, getRadioProps, setValue } = useRadioGroup({
        name: "itemFilter",
        defaultValue: props?.viewSettings?.filterValue,
        onChange: handleFilterChange
    })

    const itemFilterGroup = getRootProps()
    ///////////////////////////////////////////////////    

    const appendWordsData = async () => {
        const sortOrder = viewSettings.sortOrder
        if (!(dataRepo[sortOrder].isComplete)) {
            let requestId
            try {
                requestId = openRequest()
                const newItems = await getWordList(
                    sortOrder,
                    dataRepo[sortOrder].items.length
                )

                if (Array.isArray(newItems)) {
                    dataRepo[sortOrder] = {
                        items: dataRepo[sortOrder].items.concat(newItems),
                        isComplete: newItems.length === 0
                    }

                    setDataRepo({
                        ...dataRepo
                    })
                }
            } catch (error) {
                console.log(error)
            } finally {
                closeRequest(requestId)
            }
        }
    }


    const handleScroll = debounce(async (e) => {
        
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            appendWordsData()
        }
    }, 200)

    ///////////////////////////////////////////////////
    // scrolling
    const wordListRef = useRef()

    const getScrollPosition = () => {
        if (wordListRef?.current) {
            console.log(`wordListRef.current.scrollTop=${wordListRef.current.scrollTop}`)
            return wordListRef.current.scrollTop
        } else {
            return 0
        }
    }

    useEffect(()=> {
        console.log(`props.scrollPosition: ${props.scrollPosition}`)

        setTimeout(()=> {
            if (wordListRef?.current) {
                wordListRef.current.scrollTop = props.scrollPosition
                if (props.viewSettings?.filterValue) {
                    setValue(props.viewSettings.filterValue)
                }
            }
        }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordListRef?.current])

    ///////////////////////////////////////////////////    

    const alphabetButtons = Array.from(Array(26)).map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x))
        .map((x) => 
            <WrapItem
                key={x} 
            >
                <Button
                    colorScheme="blue"
                    type="button"
                    width="30px"
                    query={`${x}%`}
                    onClick={async (e) => {
                        const sortOrder = `${value}_${x}`
                        if (viewSettings?.sortOrder !== sortOrder) {
                            if (!(sortOrder in dataRepo)) {
                                let requestId
                                try {
                                    requestId = openRequest()
                                    const items = await getWordList(sortOrder, 0)

                                    if (Array.isArray(items)) {
                                        dataRepo[sortOrder] = {
                                            items,
                                            isComplete: items.length === 0
                                        }
                                        setDataRepo({
                                            ...dataRepo
                                        })
                                    }
                                } catch (error) {
                                    console.log(error)
                                } finally {
                                    closeRequest(requestId)
                                }
                            }
                            setViewSettings({
                                ...viewSettings,
                                "filterValue": value,
                                "sortOrder": sortOrder
                            })
                        }
                    }}
                >
                    {x}
                </Button>
            </WrapItem>
        )

    ////////////////////////////////////////////////////////////

    const slideIndexRef = useRef(0)

    const RenderSlides = (props) => {
        console.log("start RenderSlides")

        const wordSlides = []
        props.wordsData.forEach((word, index) => {
            wordSlides.push (
                <SplideSlide
                    key={`slide_${index}`}
                    width="80%"
                >
                    <Box
                        border="1px"
                        borderColor="gray.500"
                        m="30px 10px 30px 10px"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Center
                            fontSize="4xl"
                            height="160px"
                            m="0px 40px 0px 40px"
                            maxW="80%"                    
                        >
                            {word.displayName}
                        </Center>
                    </Box>
                </SplideSlide>
            )
        })

        return (
            props.wordsData && props.wordsData.length > 0 ?
                <Splide
                    ref={splideRef}
                    options={{
                        start: props.index,
                        pagination: false
                    }}
                    onMove={async (slide)=>{
                        slideIndexRef.current = slide.index
                        if (slide.index + 1 >= dataRepo[viewSettings.sortOrder].items.length) {
                            await appendWordsData()
                        }
                        console.log(`slide.index: ${slide.index}`)
                    }}
                    onClick={(e)=> {
                        setSelectedWord({
                            ...selectedWord,
                            index: slideIndexRef.current
                        })
                        setViewSettings({
                            ...viewSettings,
                            startIndex: slideIndexRef.current
                        })                        
                    }}
                    height="160px"
                >
                    {wordSlides}
                </Splide>
                :
                <></>
        )
    }

    useEffect(()=> {
        if (splideRef?.current) {
            if (slideIndexRef.current) {
                    setTimeout(() => {
                    ["<", ">"].forEach((arrow) => 
                        splideRef.current.go(`${arrow}`)
                    )
                }, 50)
            }
        }
    }, [selectedWord])

    ////////////////////////////////////////////////////////////

    const switchToFlashCardMode = () => {
        setViewSettings(
            {
                ...viewSettings,
                "isFlashCardMode": true,
                "startIndex": selectedWord.index
            }
        )
        slideIndexRef.current = selectedWord.index
    }


    ////////////////////////////////////////////////////////////
    // 

    useEffect(()=> {
        if (getDaysUntilExpire() < 0) {
            const filterValue = "registerTime"
            handleFilterChange(filterValue)
            setValue(filterValue)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    ////////////////////////////////////////////////////////////


    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"
        >
            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <Text
                        margin="2px 0px 0px 8px"
                        fontSize="xl"
                    >
                        {getUserName()} さんの単語
                    </Text>
                }
            />            

            <Flex justifyContent="center" alignItems="start" flexWrap="wrap" {...itemFilterGroup}>
                {filterOptions.map((value) => {
                    const radio = getRadioProps({ value })
                    return (
                        <RadioCard 
                            key={value}
                            {...radio}
                        >
                            {filterLabels(value)}
                        </RadioCard>
                    )
                })}
            </Flex>

            {
                value === "startsWith" ?

                    <Wrap 
                        spacing={1}
                        border="1px"
                        borderColor="blue.100"
                        padding="10px"
                        mb="20px"
                    >
                        {alphabetButtons}
                    </Wrap>                
                    :
                    <></>

            }

            <Center
                m="10px 0px 10px 0px"
            >
                <Button
                    colorScheme="blue"
                    isDisabled={!(viewSettings?.sortOrder && viewSettings.sortOrder in dataRepo)}
                    onClick={() => {
                        setViewSettings({
                            ...viewSettings,
                            "startIndex": 0,
                            "isFlashCardMode": !viewSettings.isFlashCardMode
                        })                        
                    }}
                    width="180px"
                >
                    {viewSettings.isFlashCardMode ? "単語リストモードへ" : "単語帳モードへ"}
                </Button>
            </Center>
            
            {
                viewSettings.isFlashCardMode ?
                    <RenderSlides
                        index={viewSettings.startIndex}
                        wordsData={viewSettings?.sortOrder && viewSettings.sortOrder in dataRepo ?
                            dataRepo[viewSettings.sortOrder].items
                            :
                            []
                        }
                        selectedWord={selectedWord}
                        setSelectedWord={setSelectedWord}
                        viewSettings={viewSettings}
                        setViewSettings={setViewSettings}
                    />
                    :
                    viewSettings.sortOrder ?
                        <Box
                            padding={"0px 0px 30px 10px"}
                        >
                            <Box
                                borderBottom="2px"
                                borderColor="gray.100"
                                pb="4px"
                            >
                            <HStack>
                                {
                                    value === "startsWith" ?
                                        <Box
                                            width="70px"
                                            pl="10px"
                                        >
                                            単語
                                        </Box>
                                        :
                                        value === "quizAccuracy" ?
                                            <>
                                                <Box
                                                    width="100px"
                                                    pl="10px"
                                                >
                                                    正解率
                                                    <ReverseOrderButton/>
                                                </Box>
                                                <Box
                                                    width="70px"
                                                    pl="10px"
                                                >
                                                    単語
                                                </Box>
                                            </>
                                            :
                                            value === "registerTime" ?
                                                <>
                                                    <Box
                                                        width="160px"
                                                        pl="10px"
                                                    >
                                                        登録日
                                                        <ReverseOrderButton/>
                                                    </Box>
                                                    <Box
                                                        width="70px"
                                                        pl="10px"
                                                    >
                                                        単語
                                                    </Box>
                                                </>
                                                :
                                                value === "lastStudiedTime" ?
                                                    <>
                                                        <Box
                                                            width="160px"
                                                            pl="10px"
                                                        >
                                                            最終学習日
                                                            <ReverseOrderButton/>
                                                        </Box>
                                                        <Box
                                                            width="70px"
                                                            pl="10px"
                                                        >
                                                            単語
                                                        </Box>
                                                    </>
                                                    :                                           
                                                    value === "starred" ?
                                                        <>
                                                            <Box
                                                                width="70px"
                                                                pl="10px"
                                                            >
                                                                単語
                                                            </Box>
                                                        </>
                                                        :
                                                        <></>
                                }
                            </HStack>
                            </Box>
                            <Box
                                rounded="md"
                                border="0px 0px 10px 0px"
                                borderColor="gray.100"
                                padding={"0px 10px 30px 10px"}
                                height="65vh"
                                display="flex"
                                flexDirection="column"
                            >
                                {
                                    viewSettings.sortOrder in dataRepo && dataRepo[viewSettings.sortOrder].items.length > 0 ?
                                        <Box
                                            overflowY="auto"
                                            onScroll={handleScroll}
                                            flex="1"
                                            ref={wordListRef}
                                        >
                                        {
                                            dataRepo[viewSettings.sortOrder].items.map((word, index) =>
                                                <HStack
                                                    key={`${index}`}
                                                    onClick={() => {
                                                            console.log(`clicked on ${getValueFromWord(word, "displayName")}`)
                                                            setSelectedWord({
                                                                ...selectedWord,
                                                                index
                                                            })
                                                    }}
                                                    height={`${itemHeight}px`}
                                                    borderBottom="1px solid #EDF2F7"
                                                >
                                                    {
                                                        value === "startsWith" ?
                                                            <Box
                                                                flex="1"
                                                            >
                                                                {getValueFromWord(word, "displayName")}
                                                            </Box>
                                                            :
                                                            value === "quizAccuracy" ?
                                                                <>
                                                                    <Box
                                                                        width="100px"
                                                                    >
                                                                        {convertToPercentage(word.quizAccuracy)}
                                                                    </Box>
                                                                    <Box
                                                                        flex="1"
                                                                    >
                                                                        {getValueFromWord(word, "displayName")}
                                                                    </Box>
                                                                </>
                                                                :
                                                                value === "registerTime" ?
                                                                    <>
                                                                        <Box
                                                                            width="160px"
                                                                        >
                                                                            {parseDateTimeStringToJaShortDate(word.registeredAt)}
                                                                            &nbsp;
                                                                            {parseDateTimeStringToJaTime(word.registeredAt)}
                                                                        </Box>
                                                                        <Box
                                                                            flex="1"
                                                                        >
                                                                            {getValueFromWord(word, "displayName")}
                                                                        </Box>
                                                                    </>
                                                                    :
                                                                    value === "lastStudiedTime" ?
                                                                        <>
                                                                            <Box
                                                                                width="160px"
                                                                            >
                                                                                {parseDateTimeStringToJaShortDate(word.lastStudiedTime)}
                                                                                &nbsp;
                                                                                {parseDateTimeStringToJaTime(word.lastStudiedTime)}

                                                                            </Box>
                                                                            <Box
                                                                                flex="1"
                                                                            >
                                                                                {getValueFromWord(word, "displayName")}
                                                                            </Box>
                                                                        </>
                                                                        :
                                                                        value === "starred" ?
                                                                            <>
                                                                                <Box
                                                                                    flex="1"
                                                                                >
                                                                                    {getValueFromWord(word, "displayName")}
                                                                                </Box>
                                                                            </>
                                                                            :
                                                                            <></>
                                                    }
                                                </HStack>
                                            )
                                        }
                                        </Box>
                                        :
                                        <Box>
                                            登録単語がありません。
                                        </Box>
                                }
                            </Box>
                        </Box>
                        :
                        <></>
            }

            <WordSnippetDialog
                cId={viewSettings?.sortOrder ? getValueFromWord(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "cId"): null}
                wordId={viewSettings?.sortOrder ? getValueFromWord(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "wordId"): null}
                displayName={viewSettings?.sortOrder ? getValueFromWord(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "displayName"): ""}
                meaning={viewSettings?.sortOrder ? getValueFromWord(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "meaning", 0): ""}
                isStarred={viewSettings?.sortOrder ? getValueFromWord(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "isStarred"): false}
                setStar={(value)=>{
                    if (viewSettings?.sortOrder) {
                        dataRepo[viewSettings.sortOrder].items[selectedWord.index].isStarred = value
                        setDataRepo({
                            ...dataRepo
                        })
                    }
                }}
                wordImageUrl={viewSettings?.sortOrder ? getWordMediaUrls(dataRepo[viewSettings.sortOrder].items[selectedWord.index], "image", getSases()): ""}
                selectedWord={selectedWord}
                setSelectedWord={setSelectedWord}
                getScrollPosition={getScrollPosition}
                switchToFlashCardMode={viewSettings.isFlashCardMode ? null : switchToFlashCardMode}
                viewSettings={viewSettings}
                dataRepo={dataRepo}
                dispatch={props.dispatch}
                
            />

            <Box
                m="0px 0px 10px 20px"
                height="40px"
            >
                <BackButton
                    dispatch={props.dispatch}
                />
            </Box>
        </Stack>    
    )
}

export default WordList