import React, { useRef } from 'react';
import {
    Center
} from "@chakra-ui/react";

const SoundEffect = (props) => {
    const audioRef = useRef(null)

    const handlePlay = () => {
        if (typeof props.dispatch !== "undefined") {
            props.dispatch()
        }
        audioRef.current.play()
    };

    return (
        props.url ?
            <Center
                bg={
                    props.url.includes("female") ?
                        "red.50"
                        :
                        props.url.includes("male") ?
                            "blue.50"
                            :
                            "gray.50"
                }
                border="1px"
                borderColor={
                    props.url.includes("female") ?
                        "red"
                        :
                        props.url.includes("male") ?
                            "blue"
                            :
                            "gray"
                }
                width={`${props.size}px`}
                height={`${props.size}px`}
                pt="1px"
                fontSize="larger"
            >
                <audio
                    ref={audioRef}
                    preload="auto"
                >
                    <source
                        src={props.url}
                        type="audio/mpeg"
                    />
                </audio>
                <button
                    type="button"
                    onClick={handlePlay}
                >
                    🔊
                </button>
            </Center>
            :
            <></>
    )
}
export default SoundEffect