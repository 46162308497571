import React from "react";
import {
    Box,
    Flex,
    Image
} from "@chakra-ui/react";
import eikatuLogo from "../assets/icons/eikatsu-logo.png"

const HeaderComponent = ({content, setLaunchSubApp}) => (

    <Flex
        width="100%"
    >
        <Box 
            width="56px"
        >
            <Image
                src={eikatuLogo} alt="英活"
                onClick={()=>{
                    if (setLaunchSubApp) {
                        setLaunchSubApp("toHome")
                    } else {
                        window.location.href="/"
                    }
                }}
            />
        </Box>
        <Box
            flex="1"
            margin="8px 0px 0px 6px"
        >
            {content}
        </Box>                
    </Flex>

)

export default HeaderComponent