import React, { useEffect, useState } from "react";
import {
    Spinner,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from '@chakra-ui/react'

import { useFullCoverSpinnerContext } from "../context/fullCoverSpinnerContext"

const FullCoverSpinner = (props) => {

    const {
        idSet,
        isBusy,
        isTimeout
    } = useFullCoverSpinnerContext()

    const [wasBusy, setWasBusy] = new useState(false)

    useEffect(() => {
        if (isBusy()) {
            if (!wasBusy) {
                setWasBusy(true)
                props.onOpen()
                const id = setInterval(() => {
                    if (!isBusy() || isTimeout()) {
                        setWasBusy(false)
                        props.onClose()
                        clearTimeout(id)
                    }
                }, 100)
            }
        } else {
            props.onClose()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSet])

    return (
        <Modal 
            isOpen={props.isOpen} 
            onClose={props.onClose}
            isCentered={true}
        >
            <ModalOverlay />
            <ModalContent
                bg="rgba(0, 0, 0, 0)"
            >
                <ModalBody>
                    <Center>
                        <Spinner
                            thickness='20px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            margin="10% 0% 0% 0%"
                            height="150px"
                            width="150px"
                        />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default FullCoverSpinner