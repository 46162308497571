const QuizSentence = (props) => {
    // return (<div>{renderHtml}</div>)
    const reFilled = RegExp(/<filled\s*\/>/g)
    const replacement = "　　　　　"

    const quizSentence = props.quizSentence
    const renderHtml = []
    const m = reFilled.exec(quizSentence)

    if (m) {
        const preSentence = quizSentence.substring(0, m.index)
        const postSentence = quizSentence.substring(m.index + m[0].length)
        renderHtml.push(<span key="0">{preSentence}</span>)
        renderHtml.push(<span key="1" className="underline">{replacement}</span>)
        renderHtml.push(<span key="2">{postSentence}</span>)
    } else {
        renderHtml.push(<span key="0">{quizSentence}</span>)
    }

    return (<div>{renderHtml}</div>)
    



    // return (<div>{renderHtml}</div>)
    // const filled = "<filled/>"
    // const replacement = "　　　　　"

    // const quizSentence = props.quizSentence
    // const renderHtml = []
    // const index = quizSentence.indexOf(filled)
    
    // if (index >= 0) {
    //     const preSentence = quizSentence.substring(0, index)
    //     const postSentence = quizSentence.substring(index + filled.length)
    //     renderHtml.push(<>{preSentence}</>)
    //     renderHtml.push(<span class="underline">{replacement}</span>)
    //     renderHtml.push(<>{postSentence}</>)
    // } else {
    //     renderHtml.push(<>{quizSentence}</>)
    // }

    // return (<div>{renderHtml}</div>)
}

export default QuizSentence

