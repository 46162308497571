import {
    getMediaUrl
} from "./CommonFunction"


export function getElementIdByIndex(
    word,
    key,
    meaningIndex,
    sentenceIndex = undefined
)
{
    let idMap = {}

    if (word && word?.meanings) {
        if (["meaning", "sentence"].includes(key)) {
            if (word.meanings.length > meaningIndex && meaningIndex >= 0) {
                idMap.meaningId = word.meanings[meaningIndex].meaningId
            } else {
                return undefined
            }
        }
        if (["sentence"].includes(key)) {
            if (word.meanings.length > meaningIndex && meaningIndex >= 0
                && word.meanings[meaningIndex].sentences.length > sentenceIndex && sentenceIndex >= 0) {
                    idMap.sentenceId = word.meanings[meaningIndex].sentences[sentenceIndex].sentenceId
            } else {
                return undefined
            }
        }
    }
    return idMap
}

export function getValueFromWord(word,
    key,
    meaningIndex = undefined,
    sentenceIndex = undefined
)
{
    try {
        // console.log(`getValueFromWord params: key:${key}, meaningIndex:${meaningIndex}, sentenceIndex:${sentenceIndex}`)
        switch (key) {
            case "isNew":
                return word?.isNew && word.isNew ? true : false
            case "wordId":
                return word.wordId
            case "stem":
                return word.stem
            case "wordAudio":
                return word?.audio
            case "isStarred":
                return word?.isStarred ? word.isStarred : false
            case "notes":
                return word?.notes ? word.notes : ""
            case "wordOrigin":
                return word.origin
            case "selectedMeaningId":
                return word?.selectedMeaningId ? word.selectedMeaningId : undefined
            case "meaningCandidates":
                if (word?.meaningCandidates) {
                    return word.meaningCandidates
                } else {
                    return undefined
                }
            case "episode":
                if (word?.episode) {
                    return word.episode
                } else {
                    return ""
                }
            case "wordImageFile":
                if (word?.imageFile) {
                    return word.imageFile
                } else {
                    return ""
                }
            case "quizHistory":
                if (word?.quizHistory) {
                    return word.quizHistory
                } else {
                    return []
                }
            case "hasUserOriginMeaning":
                if (word?.hasUserOriginMeaning) {
                    return word.hasUserOriginMeaning
                } else {
                    return false
                }
            case "isRedirect":
                if (word?.isRedirect) {
                    return word.isRedirect
                } else {
                    return false
                }
            case "originalDisplayName":
                if (word?.originalDisplayName) {
                    return word.originalDisplayName
                } else {
                    return ""
                }
            default:
                if (word?.meanings) {
                    switch (key) {
                        case "displayName":
                            if (meaningIndex && word.meanings[meaningIndex]?.displayName) {
                                return word.meanings[meaningIndex].displayName
                            } else {
                                return word.displayName
                            }
                        case "pronunciation":
                            if (meaningIndex && word.meanings[meaningIndex]?.pronunciation) {
                                return word.meanings[meaningIndex].pronunciation
                            } else {
                                return word.pronunciation
                            }
                        case "pos":
                            return word.meanings[meaningIndex].pos
                        case "meaning":
                            if (meaningIndex < 0 || word.meanings.length <= meaningIndex) {
                                return null
                            } else {
                                return word.meanings[meaningIndex].meaning
                            }
                        case "meaningId":
                            return word.meanings[meaningIndex].meaningId
                        case "sentence":
                            return word.meanings[meaningIndex].sentences[sentenceIndex].sentence
                        case "sentenceId":
                            return word.meanings[meaningIndex].sentences[sentenceIndex].sentenceId
                        case "sentenceMeaning":
                            return word.meanings[meaningIndex].sentences[sentenceIndex].meaning
                        case "sentenceHasImage":
                            return word.meanings[meaningIndex].sentences[sentenceIndex]?.hasImage ? word.meanings[meaningIndex].sentences[sentenceIndex].hasImage : false
                        case "sentenceAudio":
                            return word.meanings[meaningIndex].sentences[sentenceIndex]?.audio
                        case "quizSentence":
                            return word.meanings[meaningIndex].sentences[sentenceIndex]?.quiz?.sentence
                        case "quizAnswer":
                            return word.meanings[meaningIndex].sentences[sentenceIndex]?.quiz?.answer
                        case "quizOtherCandidates":
                            return word.meanings[meaningIndex].sentences[sentenceIndex]?.quiz?.otherCandidates
                        case "meaningOrigin":
                            return word.meanings[meaningIndex].origin
                        case "sentenceOrigin":
                            return word.meanings[meaningIndex].sentences[sentenceIndex].origin
                        case "cId":
                            return word?.cId ? word.cId : ""
                        case "selectedMeaning":
                            const selectedMeaningSentence = chooseSelectedMeaningSentence(word)
                            if (selectedMeaningSentence) {
                                return getValueFromWord(word, "meaning", selectedMeaningSentence.meaningIndex)
                            } else {
                                return null
                            }

                        case "tags":
                            if (word.meanings[meaningIndex]?.tags) {
                                return word.meanings[meaningIndex].tags
                            } else {
                                return []
                            }
                        case "synonyms":
                            if (word.meanings[meaningIndex]?.synonyms) {
                                return word.meanings[meaningIndex].synonyms
                            } else {
                                return []
                            }
                        case "usages":
                            if (word.meanings[meaningIndex]?.usages) {
                                return word.meanings[meaningIndex].usages
                            } else {
                                return []
                            }
                        case "compliments":
                            if (word.meanings[meaningIndex]?.compliments) {
                                return word.meanings[meaningIndex].compliments
                            } else {
                                return []
                            }
                        default:
                            return undefined
                    }
                } else {
                    return undefined
                }
        }
    } catch (error) {
            console.log(`error:${error}, key: ${key}, meaningIndex: ${meaningIndex}, sentenceIndex: ${sentenceIndex}`)
            console.log(`word:${JSON.stringify(word)}`)
    }

    return undefined
}

export function getIndexesById(word, meaningId, sentenceId=undefined) {
    let meaningIndex = undefined
    let sentenceIndex = undefined

    word.meanings.some((meaning, index) => {
        if (meaning.meaningId === meaningId) {
            meaningIndex = index
            return true
        } else {
            return false
        }
    })

    if (meaningIndex !== undefined && sentenceId !== undefined) {
        word.meanings[meaningIndex].sentences.some((sentence, index) => {
            if (sentence.sentenceId === sentenceId) {
                sentenceIndex = index
                return true
            } else {
                return false
            }
        })
    }

    return [meaningIndex, sentenceIndex]
}

export function getValueFromWordById(word, key, idMap) {
    const [meaningIndex, sentenceIndex] = getIndexesById(word, idMap.meaningId, idMap?.sentenceId)
    return getValueFromWord(word,
        key,
        meaningIndex,
        sentenceIndex
    )
}

export function setValueToWord(word,
    key,
    value,
    meaningIndex = undefined,
    sentenceIndex = undefined
)
{
    if (Object.keys(word).length === 0) {
        return undefined
    } else {
        switch (key) {
            // case "wordId":
            // case "stem":
            // case "displayName":
            case "pronunciation":
                if (meaningIndex && word.meanings[meaningIndex]?.pronunciation) {
                    word.meanings[meaningIndex].pronunciation = value
                } else {
                    word.pronunciation = value
                }
                return word
            case "pos":
                if (word.meanings.length > meaningIndex && meaningIndex >= 0) {
                    word.meanings[meaningIndex].pos = value
                    return word
                } else {
                    return undefined
                }
            case "meaning":
                if (word.meanings.length > meaningIndex && meaningIndex >= 0) {
                    word.meanings[meaningIndex].meaning = value
                    return word
                }
                return undefined
            case "sentence":
                if (word.meanings.length > meaningIndex && meaningIndex >= 0
                    && word.meanings[meaningIndex].sentences.length > sentenceIndex && sentenceIndex >= 0) {
                    word.meanings[meaningIndex].sentences[sentenceIndex].sentence = value
                    return word
                }
                return undefined
            case "sentenceMeaning":
                if (word.meanings.length > meaningIndex && meaningIndex >= 0
                    && word.meanings[meaningIndex].sentences.length > sentenceIndex && sentenceIndex >= 0) {
                    word.meanings[meaningIndex].sentences[sentenceIndex].meaning = value
                    return word
                }
                return undefined
            case "sentenceHasImage":
                if (word.meanings.length > meaningIndex && meaningIndex >= 0
                    && word.meanings[meaningIndex].sentences.length > sentenceIndex && sentenceIndex >= 0) {
                    word.meanings[meaningIndex].sentences[sentenceIndex].hasImage = value
                    return word
                }
                return undefined
            case "notes":
                word.notes = value
                return word
            case "selectedMeaningId":
                word.selectedMeaningId = value
                return word
            case "hasUserOriginMeaning":
                word.hasUserOriginMeaning = value
                return word
            default:
                return undefined
        }    
    }
}

export function setValueToWordById(word,
    key,
    value,
    idMap
) {
    let meaningIndex = undefined
    let sentenceIndex = undefined

    const elementKey = ["pronunciation", "notes", "selectedMeaningId"].includes(key) ?
        "root"
        :
        ["meaning", "pos"].includes(key) ?
            "meaning"
            :
            ["sentence", "sentenceMeaning", "sentenceHasImage"].includes(key) ?
                "sentence"
                :
                undefined

    if (elementKey && word && word?.meanings) {
        if (elementKey !== "root") {
            word.meanings.some((meaning, mIndex) => {
                if (meaning.meaningId === idMap.meaningId) {
                    meaningIndex= mIndex
                    if (elementKey !== "meaning") {
                        meaning.sentences.some((sentence, sIndex) => {
                            if (sentence.sentenceId === idMap.sentenceId) {
                                sentenceIndex = sIndex
                                return true
                            } else {
                                return false
                            }
                        })
                    }
                    return true
                } else {
                    return false
                }
            })
        }
        return setValueToWord(word, key, value, meaningIndex, sentenceIndex)
    }
    return undefined
}

export function removeElementFromWord(word, key, idMap)
{
    //console.log(`idMap:${JSON.stringify(idMap)}`)
    let removedMeaningIndex = undefined
    let removedSentenceIndex = undefined

    if (word && word?.meanings) {
        if ("meaningId" in idMap) {
            word.meanings.some((meaning, meaningIndex) => {
                if (meaning.meaningId === idMap.meaningId) {
                    removedMeaningIndex = meaningIndex
                    if (key !== "meaning" && "sentenceId" in idMap) {
                        word.meanings[meaningIndex].sentences.some((sentence, sentenceIndex) => {
                            if (sentence.sentenceId === idMap.sentenceId) {
                                removedSentenceIndex = sentenceIndex
                                return true
                            } else {
                                return false
                            }
                        })
                    }
                    return true
                } else {
                    return false
                }
            })

            if (key === "meaning" && removedMeaningIndex !== undefined) {
                word.meanings.splice(removedMeaningIndex, 1)
            } else if (key === "sentence" && removedMeaningIndex !== undefined && removedSentenceIndex !== undefined) {
                word.meanings[removedMeaningIndex].sentences.splice(removedSentenceIndex, 1)
            }
        }
    }

    return word
}

export function appendElementToWord(word, key, idMap) {
    let meaningExist = false
    let sentenceExist = false

    if (word && word?.meanings) {
        // console.log(`!!word:${JSON.stringify(word)}`)
        // console.log(`!!key=${key}`)

        if (key === "meaning") {
            word.meanings.some((meaning) => {
                if (meaning.meaningId === idMap.meaningId) {
                    meaningExist = true
                    return true
                } else {
                    return false
                }
            })
            if (!meaningExist) {
                word.meanings.push(
                    {
                        meaningId: idMap.meaningId,
                        origin: "user",
                        meaning: "",
                        pos: "",
                        sentences: [
                            {
                                sentenceId: idMap.sentenceId,
                                origin: "user",
                                sentence: "",
                                meaning: "",
                                hasImage: false
                            }
                        ]
                    }
                )
            }
        } else if (key === "sentence") {
            // console.log(`!!idMap:${JSON.stringify(idMap)}`)

            word.meanings.forEach((meaning, meaningIndex) => {
                if (meaning.meaningId === idMap.meaningId) {
                    // console.log('!!level2')
                    meaning.sentences.some((sentence) => {
                        if (sentence.sentenceId === idMap.sentenceId) {
                            // console.log('!!level3')
                            sentenceExist = true
                            return true
                        } else {
                            return false
                        }
                    })
                    // console.log(`!!sentenceExist:${sentenceExist}`)
                    if (!sentenceExist) {
                        word.meanings[meaningIndex].sentences.push(
                            {
                                sentenceId: idMap.sentenceId,
                                origin: "user",
                                sentence: "",
                                meaning: "",
                                hasImage: false
                            }
                        )
                        // console.log(`updated word: ${JSON.stringify(word)}`)
                    }
                }
            })
        }
    }

    return word
}

export function getSentenceMediaUrl(word, media, sases, meaningIndex, sentenceIndex) {
    let sentenceId = getValueFromWord(word, "sentenceId", meaningIndex, sentenceIndex)
    let url = null
    // console.log("meaningIndex: " + meaningIndex)
    // console.log("word: " + JSON.stringify(word))

    switch(media) {
        case "image":
            url = `${getMediaUrl()}/media/image/sentence/${sentenceId}.png?${sases.mastervocabulary}`
            break
        case "audio":
            const sentenceAuidio = getValueFromWord(word, "sentenceAudio", meaningIndex, sentenceIndex)
            if (sentenceAuidio) {
                
                Object.keys(sentenceAuidio).forEach((llcc) => {
                    sentenceAuidio[llcc].forEach((gender) => {
                        if (gender === true) {
                            url= `${getMediaUrl()}/media/audio/sentence/${llcc}/${sentenceId}.mp3?${sases.mastervocabulary}`                            
                        } else {
                            //
                        }
                    })
                })
            }
            break
        default:
            break
    }
    
    return url
}


// export function getSentenceMediaUrlById(word, media, userId, sases, idMap) {
//     const [meaningIndex, sentenceIndex] = getIndexesById(word, idMap.meaningId, idMap?.sentenceId)
//     if (meaningIndex !== undefined && sentenceIndex !== undefined) {
//         return getSentenceMediaUrl(word, media, sases, meaningIndex, sentenceIndex)
//     } else {
//         return null
//     }
// }

export function getWordMediaUrls(wordData, media, sases) {
    const urls = [];
    const wordId = getValueFromWord(wordData, "wordId")

    switch(media) {
        case "image": {
                const wordImageFile = getValueFromWord(wordData, "wordImageFile")
                if (wordImageFile) {
                    const url = `${getMediaUrl()}/media/image/episode/${wordImageFile}?${sases.mastervocabulary}`
                    urls.push(url)
                }
            }
            break
        case "audio":
            if (wordId) {
                const wordAudio = getValueFromWord(wordData, "wordAudio")
                if (wordAudio) {
                    Object.keys(wordAudio).forEach((llcc) => {
                        wordAudio[llcc].forEach((gender) => {
                            const url = `${getMediaUrl()}/media/audio/word/${llcc}/${gender}/${wordId}.mp3?${sases.mastervocabulary}`
                            urls.push(url)
                        })
                    })
                }
            }
            break
        default:
            break
    }
    return urls;
}

export function getEpisodeImageUrl(wordId, sases) {
    return `${getMediaUrl()}/media/image/episode/${wordId}.png?${sases.mastervocabulary}`
}

export function traverseUserSentencesWithImage(
    word,
    idMap=null
)
{
    const sentenceIds = []

    if (word && word?.meanings) {
        word.meanings.forEach((meaning) => {
            if (idMap === null || ("meaningId" in idMap && meaning.meaningId === idMap.meaningId)) {
                meaning.sentences.forEach((sentence) => {
                    if (idMap === null || !("sentenceId" in idMap) || ("sentenceId" in idMap && sentence.sentenceId === idMap.sentenceId)) {
                        if (sentence?.origin === "user" && sentence?.hasImage) {
                            sentenceIds.push(sentence.sentenceId)
                        }
                    }
                })
            }
        })
    }

    return sentenceIds
}

export function countMeanings(word) {
    return (word && word?.meanings) ? word.meanings.length : undefined
}

// export function getElementLengthInWord(word, key, meaningIndex=undefined) {
//     if (word && word?.meanings) {
//         if (key === "meaning") {
//             return word.meanings.length
//         } else if (key === "sentence") {
//             return word.meanings[meaningIndex].sentences.length
//         }
//     }
//     return undefined
// }

const poses = {
    "noun": "名詞",
    "verb": "動詞",
    "adjective": "形容詞",
    "adverb": "副詞",
    "phrase": "熟語",
    "conjunction": "接続詞",
    "preposition": "前置詞",
    "auxiliary verb": "助動詞",
    "exclamation": "間投詞",
    "pronoun": "代名詞",
    "article": "冠詞",
    "other": "その他"
}

export function getPoses() {
    return poses
}

export function posEtoJ(pos) {
    if (pos in poses) {
        return poses[pos]
    } else {
        return " - "
    }
}

export function posEtoJShort(pos, digit) {
    const jPos = posEtoJ(pos)
    if (pos === "other") {
        return `${jPos.substr(jPos.length - 1, 1)}${digit}`
    } else {
        return `${jPos.substr(0, 1)}${digit}`
    }
}

export function getDefinedPosCount() {
    return Object.keys(poses).length
}

export function getInusePoses(word, exceptPos="") {
    const inusedPoses = new Set()
    if (word?.meanings) {
        word.meanings.forEach((meaning) => {
            if (meaning.pos !== exceptPos) {
                inusedPoses.add(meaning.pos)
            }
        })
    }
    return inusedPoses
}


export function chooseWordSentencesRandom(words) {
    const results = []
    words.forEach((word) => {
        if (word?.meanings) {
            const meaningIndex = Math.floor(Math.random() * word.meanings.length)
            const sentenceIndex = Math.floor(Math.random() * word.meanings[meaningIndex].sentences.length)
            let anotherSentenceIndex = Math.floor(Math.random() * word.meanings[meaningIndex].sentences.length)
            if (sentenceIndex === anotherSentenceIndex)
                if (word.meanings[meaningIndex].sentences.length - 1 > sentenceIndex) {
                    ++anotherSentenceIndex
                } else {
                    anotherSentenceIndex = 0
                }

            results.push({meaningIndex, sentenceIndex, anotherSentenceIndex})
        }
    })

    // console.log("chooseWordSentencesRandom:" + JSON.stringify(results))
    return results
}

export function chooseSelectedMeaningSentence(word) {
    if (word?.meanings && word.selectedMeaningId) {
        // let selectedPosIndex = undefined
        // let selectedMeaningIndex = undefined
        let selectedMeaningIndex = 0
    
        word.meanings.some((meaning, meaningIndex) => {
            if (meaning.meaningId === word.selectedMeaningId) {
                selectedMeaningIndex = meaningIndex
                return true
            } else {
                return false
            }
        })

        let sentenceIndex = 0
        let anotherSentenceIndex = 0
        
        sentenceIndex = Math.floor(Math.random() * word.meanings[selectedMeaningIndex].sentences.length)
        anotherSentenceIndex = Math.floor(Math.random() * word.meanings[selectedMeaningIndex].sentences.length)
        if (sentenceIndex === anotherSentenceIndex) {
            if (word.meanings[selectedMeaningIndex].sentences.length - 1 > sentenceIndex) {
                ++anotherSentenceIndex
            } else {
                anotherSentenceIndex = 0
            }
        }

        return {
            meaningIndex: selectedMeaningIndex, 
            sentenceIndex, 
            anotherSentenceIndex
        }
    }
    
    return null
}

export function chooseSelectedMeaningSentences(words) {
    return words.map((word) => chooseSelectedMeaningSentence(word))
}


export function cleanWordData(wordData) {
    const word = {...wordData}

    // remove system sentences and empty folders
    if (word?.meanings) {
        let removedMeaningIndexes = []
        word.meanings.forEach((meaning, meaningIndex) => {
            if (meaning?.sentences) {
                let removedSentenceIndexes = []
                meaning.sentences.forEach((sentence, sentenceIndex) => {
                    if (sentence.origin === "system") {
                        removedSentenceIndexes.unshift(sentenceIndex)
                    }
                })
                //console.log("removedSentenceIndexes: " + JSON.stringify(removedSentenceIndexes))
                removedSentenceIndexes.forEach((i) => {meaning.sentences.splice(i, 1)})
                if (meaning.sentences.length === 0 && meaning.origin === "system") {
                    removedMeaningIndexes.unshift(meaningIndex)
                }
            }
        })
        removedMeaningIndexes.forEach((i) => word.meanings.splice(i, 1))
        if (word.meanings.length === 0) {
            delete word.meanings
        }
    }

    // console.log("after removing system sentences and empty folders: " + JSON.stringify(word))

    // remove invalid properties that were not defined here
    const level0ValidProps = [
        "wordId",
        "origin",
        "stem",
        "displayName",
        "pronunciation",
        "meanings",
        "notes",
        "userId",
        "selectedMeaningId",
        "cId",
        "isStarred",
        "hasUserOriginMeaning"
    ]

    const level1ValidProps = [
        "meaningId",
        "origin",
        "meaning",
        // "tags",
        // "synonyms",
        // "usages",
        // "compliments",
        "pos",
        "displayName",
        "pronunciation",
        "sentences"
    ]

    const level2ValidProps = [
        "sentenceId",
        "origin",
        "sentence",
        "meaning",
        "hasImage"
    ]

    let wordInvalidKeys = []
    Object.keys(word).forEach((key) => {
        if (!level0ValidProps.includes(key)) {
            wordInvalidKeys = [...wordInvalidKeys, key]
        } else if (word?.origin === "user" && ["pronunciation"].includes(key)) {
            // leave the key
        } else if (key === "meanings") {
            word.meanings.forEach((meaning) => {
                let meaningInvalidKeys = []
                Object.keys(meaning).forEach((key => {
                    if (!level1ValidProps.includes(key)) {
                        meaningInvalidKeys.push(key)
                    } else if (key === "sentences") {
                        meaning.sentences.forEach((sentence) => {
                            let sentenceInvalidKeys = []
                            Object.keys(sentence).forEach((key) => {
                                if (!level2ValidProps.includes(key)) {
                                    sentenceInvalidKeys.push(key)
                                }
                            })
                            sentenceInvalidKeys.forEach((key) => {
                                delete sentence[key]
                            })
                        })
                    }
                }))
                meaningInvalidKeys.forEach((key) => {
                    delete meaning[key]
                })
            })
        }
    })
    wordInvalidKeys.forEach((key) => {
        delete word[key]
    })

    return word
}

export function permutateWordElements(word) {

    if (word) {
        word = JSON.parse(JSON.stringify(word).replace(/\s+/g, ' '))
    }

    if (word.meanings.length === 1) {
        const firstMeaningId = getValueFromWord(word, "meaningId", 0)
        word = setValueToWord(word, "selectedMeaningId", firstMeaningId)
        return word

    } else {
        let wordData = {
            ...word,
            meanings: []
        }            
        
        const selectedMeaningId = getValueFromWord(word, "selectedMeaningId")
    
        const meaningMap = {}
        let primaryPos = undefined
        word.meanings.forEach((meaning) => {
            const pos = meaning.pos
            if (!(pos in meaningMap)) {
                meaningMap[pos] = []
            }
            if (selectedMeaningId === meaning.meaningId) {
                primaryPos = pos
                meaningMap[pos].unshift(meaning)
            } else {
                meaningMap[pos].push(meaning)
            }
        })

        if (primaryPos !== undefined) {
            wordData.meanings = [...meaningMap[primaryPos]]
        } else {
            wordData = setValueToWord(wordData, "selectedMeaningId", "")
        }

        Object.keys(meaningMap).forEach((pos) => {
            if (pos !== primaryPos) {
                wordData.meanings = wordData.meanings.concat(meaningMap[pos])
            }
        })
        return wordData
    }
}

export function validateSelectedMeaningId(word) {
    const selectedMeaningId = getValueFromWord(word, "selectedMeaningId")
    if (!selectedMeaningId) {
        return false
    } else if (!word?.meanings) {
        return false
    } else {
        return word.meanings.some((meaning) => {
            if (meaning.meaningId === selectedMeaningId) {
                return true
            } else {
                return false
            }
        })
    }
}

export function extractUserOriginMeanings(word) {
    const meanings = []

    if (word?.meanings && Array.isArray(word?.meanings)) {
        word.meanings.forEach((meaning) => {
            if (meaning.origin === "user") {
                meanings.push(meaning.meaning)
            }
        })
    }

    return meanings
}

export function countUserMeanings(word) {
    let count = 0

    if (word?.meanings && Array.isArray(word?.meanings)) {
        word.meanings.forEach((meaning) => {
            if (meaning.origin === "user") {
                ++count
            }
        })
    }

    return count
}


export function countUserSentences(word, meaningId) {
    let count = 0
    
    if (meaningId) {
        if (word?.meanings && Array.isArray(word?.meanings)) {
            word.meanings.forEach((meaning) => {
                if (meaning?.meaningId === meaningId) {
                    if (meaning?.sentences && Array.isArray(meaning?.sentences)) {
                        meaning.sentences.forEach((sentence) => {
                            if (sentence.origin === "user") {
                                ++count;
                            }
                        })
                    }
                }
            })
        }
    }

    return count
}

export function enumerageMeaningIds(word) {
    const meaningIds = []

    if (word?.meanings && Array.isArray(word?.meanings)) {
        word.meanings.forEach((meaning) => {
            meaningIds.push(meaning.meaningId)
        })
    }

    return meaningIds
}

