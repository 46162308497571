import React, { useState, useEffect} from "react";
import {
    Box
} from "@chakra-ui/react";
import '@splidejs/react-splide/css';

import "../../App.css"
import { useUserContext } from "../../context/userContext";

const NotificationBar = (props) => {

    const {
        getTotalWordCount,
        getWordCountQuota
    } = useUserContext()

    const stringResources = {
        exceedWordCountQuota: `登録可能な単語数上限 (${getWordCountQuota()}) に達しているため、単語を保存することができません。この単語を登録したい場合は、別の登録済み単語を削除してから再度登録してください`,
        notSavedYet: "変更はまだ保存されていません。画面下の [保存] ボタンを押してください",
        selectMeaning: "単語を保存するためには、記憶したい意味を１つ選択してください。"
    }

    const [notification, setNotification] = useState({mode:"", message: ""})

    useEffect(()=> {
        if (props.params.isNew && getTotalWordCount() >= getWordCountQuota()) {
            setNotification({
                mode: "warning",
                message: stringResources.exceedWordCountQuota
            })
        } else if (!props.params.selectedMeaningId) {
            setNotification({
                mode: "warning",
                message: stringResources.selectMeaning
            })
        } else if (props.params.isNew
            || (props.params.isEditted 
                && ["readyToEditWordElement", "editWordElement"].includes(props.params.mode))) {
            setNotification({
                mode: "warning",
                message: stringResources.notSavedYet
            })
        } else {
            setNotification({
                mode: "",
                message: ""
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.params.selectedMeaningId, props.params.isNew, props.params.isEditted, props.params.mode])

    let barStyle = {
        icon: "🙂",
        color: "white",
        bgColor: "gray.200"
    }

    switch(notification.mode) {
        case "alert":
            barStyle = {
                icon: "❗",
                color: "black",
                bgColor: "red.100"
            }
            break
        case "warning":
            barStyle = {
                icon: "⚠",
                color: "black",
                bgColor: "yellow.100"
            }
            break
        case "info":
            barStyle = {
                icon: "ℹ️",
                color: "black",
                bgColor: "blue.100"
            }
            break
        default:
            break        
    }

    return (
        notification.message ?
            <Box
                width="100%"
                bgColor={barStyle.bgColor}
                color={barStyle.color}
                p="2px 0px 2px 6px"
            >
                {barStyle.icon} {notification.message}
            </Box>
            :
            <></>
    )
}

export default NotificationBar