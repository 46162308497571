import { createContext, useContext, useState } from "react";
import userAsyncLocalStorage from "../hooks/useAsyncLocalStorage";
import {
    convertDateTimeInIso8601Jst,
    getNowInIso8601Jst,
} from "../CommonFunction"

const AuthContext = createContext(undefined)

export const AuthProvider = ({ children }) => {

    const {
        getAuthInfo,
        overwriteAuthInfo,
        removeItem
    } = userAsyncLocalStorage()

    const [isSignIn, setIsSignIn] = useState(false)

    return (
        <AuthContext.Provider
            value={{
                isSignIn,
                switchToSignIn: () => {
                    setIsSignIn(true)
                },
                switchToSignOut: async () => {
                    Promise.resolve()
                        .then(() => removeItem("authInfo"))
                        .then(() => setIsSignIn(false))
                },
                validateAccessToken: async (authInfo=null) => {
                    if (!authInfo) {
                        authInfo = await getAuthInfo()
                    }

                    if (authInfo?.azureInfo?.accessToken
                            && authInfo?.azureInfo?.expiredAt
                            && getNowInIso8601Jst() < convertDateTimeInIso8601Jst(authInfo.azureInfo.expiredAt)) {
                        return true
                    } else {
                        return false
                    }                
                },
                setRequestType: async (requestType) => {
                    return Promise.resolve()
                        .then(() => overwriteAuthInfo({requestType}))
                        .then((authInfo) => {return authInfo.requestType})
                },
                getRequestType: async () => {
                    return Promise.resolve()
                        .then(() => getAuthInfo())
                        .then((authInfo) => {return authInfo.requestType})
                },
                wasSignIn: async() => {
                    const authInfo = await getAuthInfo()
                    if (authInfo
                            && authInfo?.azureInfo?.accessToken
                            && authInfo.azureInfo.accessToken) {
                        return true
                    } else {
                        return false
                    }
                }
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext);