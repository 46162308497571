import React from "react";
import {
    Box,
    Center,
    Highlight,
    Stack,
    Text
} from "@chakra-ui/react";
import ImageElement from "./ImageElement";

const WordEpisode = (props) => {

    return (
        <Stack
            mb="20px"
        >
            <Box
                fontSize="xl"
                textDecoration="underline"
            >
                <Text as="span" fontWeight="bold">{props.displayName}</Text> をエピソードで覚えよう
            </Box>
            <Box
                m="0px 10px 0px 10px"
            >
                    {
                        props.episode.split(/\\n/).map((item, index) => {
                            return (
                                <Highlight 
                                    key={index}
                                    query={props.displayName}
                                    styles={{ px: '1', py: '1', bg: 'blue.100' }}
                                >
                                    {item.match(/\\n/) ? <br/> : item}
                                </Highlight>
                            )
                        })
                    }
            </Box>
            <Center>
                <ImageElement
                    url={props.imageUrl}
                    maxW="330px"
                    minW="330px"
                />
            </Center>
        </Stack>
    )
}

export default WordEpisode