import {
    Box,
    HStack,
} from "@chakra-ui/react";

const WordTags = (props) => {
    return (
        <HStack>
            {
                props.displayWordParams.isUserOriginWord ?
                    <Box
                    rounded="full"
                    bgColor="blue.100"
                    p="2px 10px 2px 10px"
                    >
                        ユーザー
                    </Box>
                    :
                    <></>
            }
            <Box
                rounded="full"
                bgColor="blue.100"
                p="2px 10px 2px 10px"
            >
                {
                    props.displayWordParams.isNew ? 
                        "新規単語"
                        :
                        "登録済"
                }
            </Box>
                
        </HStack>
    )
}

export default WordTags
