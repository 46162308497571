import React from "react";
import {
    Box,
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    WarningTwoIcon
} from "@chakra-ui/icons"
import { useErrorContext } from "../context/errorContext";

const ErrorDialog = (props) => {

    const {
        errorMessage,
        clearErrorMessage
    } = useErrorContext()    

    const onClick = () => {
        clearErrorMessage()
        props.onClose()
        if (props?.dispatch) {
            props.dispatch({
                type: props.dispatchType,
                data: {
                    previous: {},
                    current: {}
                }
            })
        }
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    <WarningTwoIcon
                        m="0px 8px 4px 0px"
                        color="red"
                        fontSize="30px"
                    />
                    {
                        props?.errorMessage?.title ?
                            props.errorMessage.title
                            :
                            errorMessage.title
                    }
                </ModalHeader>
                <ModalCloseButton 
                    onClick={() => onClick()}
                />                
                <ModalBody>
                    <Box
                        fontSize="md"
                        justifyContent="flex-start"
                    >
                        {
                            props?.errorMessage?.messages ?
                                props.errorMessage.messages.map((message) => {
                                    return (<Text>{message}</Text>)
                                })
                                :
                                errorMessage.messages.map((message) => {
                                    return (<Text>{message}</Text>)
                                })
                        }
                    </Box>
                </ModalBody>
                <ModalFooter
                    justifyContent="center"
                >
                    <Button
                        colorScheme="gray"
                        onClick={() => onClick()}
                        >
                        閉じる
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default ErrorDialog