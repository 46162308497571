import React from "react";
import {
    Box,
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Link
} from "@chakra-ui/react";
import {
    ExternalLinkIcon
} from "@chakra-ui/icons"
import { useCookies } from "react-cookie";

import { useUserContext } from "../../context/userContext"
import {
    getLicenseExpirationCookieName
} from "../../CommonFunction"

const ExpiringDialog = (props) => {

    const {
        getExpiredAt
    } = useUserContext()    

    const cookieName = getLicenseExpirationCookieName(getExpiredAt())
    const [, setCookie] = useCookies();

    const licensePurchaseLink = "https://www.sora2.net"
    
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ライセンスの有効期限切れが近づいています</ModalHeader>
                <ModalCloseButton 
                    onClick={() => {
                        setCookie(cookieName, true)
                        props.onClose()
                    }}
                />                
                <ModalBody>
                    <Box
                        fontSize="sm"
                    >
                        <Text>いつも英活アプリをご利用いただきありがとうございます。</Text>
                        <Text>
                            現在ご利用になっているライセンスが{props.dueDate}有効期限切れとなります。有効期限を過ぎますとアプリをご利用頂けません。<br/>
                            引き続きご利用になりたい場合は、事前にライセンスをご購入ください。</Text>
                        <Text>
                            <Link 
                                href={licensePurchaseLink}
                                isExternal={true}
                                color="blue.500"
                            >
                                有料ライセンスご購入ページへのリンク<ExternalLinkIcon m="2px 0px 8px 2px" />
                            </Link>
                        </Text>
                        <Text>引き続き、英活アプリを宜しくお願い致します。</Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setCookie(cookieName, true, { sameSite: 'None', secure: true })
                            props.onClose()
                        }}
                    >
                        閉じる
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default ExpiringDialog