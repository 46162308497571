import React from "react";
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    QuestionOutlineIcon
} from "@chakra-ui/icons"

const ConfirmationDialog = (props) => {

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader/>
                <ModalCloseButton 
                    onClick={() => props.onConfirmButtonClick()}
                />                
                <ModalBody>
                    <Box
                        fontSize="md"
                        justifyContent="flex-start"
                    >
                        <QuestionOutlineIcon
                            m="0px 8px 4px 0px"
                            color="red"
                            fontSize="30px"
                        />
                        { props?.message }
                    </Box>
                </ModalBody>
                <ModalFooter
                    justifyContent="center"
                >
                    <Button
                        colorScheme="red"
                        onClick={() => props.onConfirmButtonClick()}
                        m="0px 4px 0px 0px"
                    >
                        はい
                    </Button>
                    <Button
                        colorScheme="gray"
                        onClick={() => props.onClose()}
                        m="0px 0px 0px 4px"
                    >
                        キャンセル
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default ConfirmationDialog