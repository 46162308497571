import React from "react";
import {
    Box,
    HStack,
    Spacer,
    Stack,
    Text,
    Radio
} from "@chakra-ui/react";
import {
    AddIcon,
    EditIcon,
    DeleteIcon,
    CheckIcon
} from '@chakra-ui/icons'
import '@splidejs/react-splide/css';

import "../../App.css";
import {
    posEtoJShort,
    getElementIdByIndex,
    countMeanings
} from "../../WordDataFunction"

import useApi from "../../hooks/useApi";
import SentenceList from "./SentenceList";
import EmbeddedLink from "../EmbeddedLink";


const MeaningList = (props) => {

    ///////////////////////////////////////////////////
    // for clicking a link
    const {
        readWordByWordId
    } = useApi()    

    const linkClickHandler = async (wordId) => {
        const wordData = await readWordByWordId(wordId)

        if (wordData) {
            props.dispatch({
                type: "clickLink",
                data: {
                    previous: {},
                    current: {
                        wordData
                    }
                }
            })
        }        
    }
    ///////////////////////////////////////////////////

    const meaningList = []
    const isMeaningSelectable = !props.displayWordParams.hasValidSelectedMeaningId 
        || (props.displayWordParams.mode === "readyToEditWordElement"
            && countMeanings(props.displayWordParams.wordData) > 1)

    props.meanings.forEach((meaning, i) => {
        const meaningIndex = props.meaningStartIndex + i
        let idMap = getElementIdByIndex(props.displayWordParams.wordData, "meaning", meaningIndex)
        const posJShort = `[${posEtoJShort(props.pos, meaningIndex + 1)}`
        
        meaningList.push(
            <Box
                key={`${props.posIndex}_${meaningIndex}`}
                border="1px"
                borderColor="blue.100"
                p="2px"
                m="2px 2px 24px 2px"
            >
                <HStack
                    align="start"
                >
                    {
                        meaning.meaningId === props.displayWordParams.selectedMeaningId ?
                            <CheckIcon 
                                color="blue.500"
                                style={{ width: '24px', height: '24px' }}
                            />
                            :
                            <></>
                    }
                    <Box
                        bgColor="blue.500"
                        textColor="white"
                        textAlign="center"
                        minWidth="42px"
                        maxWidth="42px"
                        mt="4px"
                    >
                        {`${posEtoJShort(meaning.pos, i + 1)}`}
                    </Box>
                    <Box
                        fontSize="xl"
                        pl="2px"
                    >
                        <HStack>
                            {
                                meaning?.tags ?
                                    meaning.tags.map((tag, index) => {
                                        return (
                                            <Box
                                                bgColor="gray.400"
                                                textColor="white"
                                                textAlign="center"
                                                width="18px"
                                                height="18px"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                fontSize="md"
                                                pb="2px"
                                                key={index}
                                            >
                                                {tag}
                                            </Box>
                                        )
                                    })
                                    :
                                    <></>
                            }
                            <Box>
                                <EmbeddedLink
                                    sourceText={meaning.meaning}
                                    needLink={true}
                                    clickHandler={linkClickHandler}
                                />
                            </Box>
                        </HStack>
                        {
                            isMeaningSelectable ?
                                <Box
                                    borderBottom="2px"
                                    borderColor="red.500"
                                    display="flex"
                                    alignItems="center"
                                    p="0px 8px 0px 4px"
                                    width="fit-content"
                                >
                                    <Radio 
                                        value={meaning.meaningId}
                                        colorScheme="red"
                                    >
                                        <Text as="span" color="red.500">この意味で記憶する</Text>
                                    </Radio>
                                </Box>
                                :
                                <></>
                        }

                        {
                            meaning?.synonyms && meaning.synonyms.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        同義
                                    </Box>
                                    <Box 
                                        flex="1"
                                        fontSize="md"
                                        pb="3px"
                                    >
                                        <EmbeddedLink
                                            sourceText={meaning.synonyms.join(", ")}
                                            needLink={true}
                                            clickHandler={linkClickHandler}
                                        />                                        
                                    </Box>                                        
                                </HStack>
                                :
                                <></>
                        }

                        {
                            meaning?.usages && meaning.usages.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        用例
                                    </Box>
                                    <Stack 
                                        flex="1"
                                        fontSize="md"
                                    >
                                        <Box>
                                            <EmbeddedLink
                                                sourceText={meaning.usages.join(", ")}
                                                needLink={true}
                                                clickHandler={linkClickHandler}
                                            />                                        
                                        </Box>
                                    </Stack>                                        
                                </HStack>
                                :
                                <></>
                        }

                        {
                            meaning?.compliments && meaning.compliments.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        補足
                                    </Box>
                                    <Stack 
                                        flex="1"
                                        fontSize="md"
                                    >
                                        {
                                            meaning.compliments.map((compliment, index) => {
                                                return (
                                                    <Box 
                                                        key={index} 
                                                        pb="3px"
                                                    >
                                                        <EmbeddedLink
                                                            sourceText={compliment}
                                                            needLink={true}
                                                            clickHandler={linkClickHandler}
                                                        />
                                                    </Box>                                                        
                                                )
                                            })
                                        }
                                    </Stack>                                        
                                </HStack>
                                :
                                <></>
                        }                            

                    </Box>
                    <Spacer/>
                    {
                        props.displayWordParams.mode === "readyToEditWordElement" ?
                            <>
                                <AddIcon
                                    onClick={() => {
                                        if (!props.displayWordParams.exceedMaxUserMeaningCount) {
                                            props.dispatch({
                                                type: "displayWordEditDialogOpened",
                                                data: {
                                                    previous:{
                                                        search: props.searchParams
                                                    },
                                                    current: {
                                                        wordData: props.displayWordParams.wordData,
                                                        editDialogParams: {
                                                            type: "meaningAdd",
                                                            header: `新しい意味を追加します`,
                                                            displayName: props.displayWordParams.displayName,
                                                            pronunciation: props.displayWordParams.pronunciation,
                                                            pos: props.pos,
                                                            inusedPoses: props.displayWordParams.inusedPoses,
                                                            meaning: "",
                                                            sentence: "",
                                                            sentenceMeaning: "",                                                        
                                                            idMap: {
                                                                meaningId: crypto.randomUUID(),
                                                                sentenceId: crypto.randomUUID()
                                                            },
                                                            isOpen: true
                                                        },
                                                        isEditted: props.displayWordParams.isEditted
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    color={
                                        props.displayWordParams.exceedMaxUserMeaningCount ?
                                            "gray.300"
                                            :
                                            "black"
                                    }
                                />
                                {
                                    meaning.origin === "user" ?
                                        <>
                                            <EditIcon
                                                onClick={() => {
                                                    props.dispatch({
                                                        type: "displayWordEditDialogOpened",
                                                        data: {
                                                            previous:{
                                                                search: props.searchParams
                                                            },
                                                            current: {
                                                                wordData: props.displayWordParams.wordData,
                                                                editDialogParams: {
                                                                    type: "meaningEdit",
                                                                    header: `[${posJShort}] の意味を変更します`,
                                                                    displayName: props.displayWordParams.displayName,
                                                                    pronunciation: props.displayWordParams.pronunciation,
                                                                    pos: props.pos,
                                                                    inusedPoses: props.displayWordParams.inusedPoses,
                                                                    meaning: meaning.meaning,
                                                                    idMap,
                                                                    isOpen: true
                                                                },
                                                                isEditted: props.displayWordParams.isEditted
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            {
                                                countMeanings(props.displayWordParams.wordData) > 1 ?
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            props.dispatch({
                                                                type: "displayWordEditDialogOpened",
                                                                data: {
                                                                    previous:{
                                                                        search: props.searchParams
                                                                    },
                                                                    current: {
                                                                        wordData: props.displayWordParams.wordData,
                                                                        editDialogParams: {
                                                                            type: "meaningDelete",
                                                                            header: `意味：[${posJShort}] を削除します。この意味に設定されたすべての情報が削除されます。※削除すると復元することはできません`,
                                                                            meaning: meaning.meaning,
                                                                            idMap,
                                                                            isOpen: true
                                                                        },
                                                                        isEditted: props.displayWordParams.isEditted
                                                                    }
                                                                }
                                                            })
                                                        }}                                                
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                        }
                </HStack>
                <SentenceList
                    displayWordParams={props.displayWordParams}
                    searchParams={props.searchParams}                    
                    pos={props.pos}
                    posIndex={props.posIndex}
                    meaning={meaning}
                    meaningIndex={meaningIndex}
                    idMap={idMap}
                    sentences={meaning.sentences}
                    imageRepo={props.imageRepo}
                    userSentenceImages={props.userSentenceImages}
                    setUserSentenceImages={props.setUserSentenceImages}
                    dispatch={props.dispatch}
                />
            </Box>
        )
    })

    return (
        <>
            {meaningList}
        </>
    )
}

export default MeaningList