import { createContext, useContext } from "react";
import {
    getElapsedTime,
    getNowInIso8601Jst,
    convertDateTimeInIso8601Jst
} from "../CommonFunction"
import { wordDB } from "../database/wordDB"
import { imageDB } from "../database/imageDB"

const UserContext = createContext(undefined)

export const UserProvider = ({ children }) => {
    const initialUserInfo = {
        userId: "",
        email: "",
        displayName: "",
        settings: {
            quizMode: "",
            fsrsRetention: 0,
            maxQuizCount: 0,
            expiredAt: "2100-01-01T00:00:00+09:00",
            daysUntilExpire: 9999
        }
    }

    const getUserInfo = () => {
        return (    
            sessionStorage?.userInfo ?
                JSON.parse(sessionStorage.userInfo)
                :
                initialUserInfo
        )
    }

    const setUserInfo = (userInfo) => {
        sessionStorage.userInfo = JSON.stringify(userInfo)
    }
    
    const initialUserStatus = {
        allWordCount: 0,
        notYetUpdated: true
    }

    const setUserStatus = (userStatus) => {
        sessionStorage.userStatus = JSON.stringify(userStatus)
    }

    const getUserStatus = () => {
        return (    
            sessionStorage?.userStatus ?
                JSON.parse(sessionStorage.userStatus)
                :
                initialUserStatus
        )
    }

    const getWordHistoryFilter = () => {
        return (
            sessionStorage?.wordHistoryFilter ?
                sessionStorage.wordHistoryFilter
                :
                ""
        )
    }

    const setWordHistoryFilter = (value) => {
        sessionStorage.wordHistoryFilter = value
    }

    const getWordListFilter = () => {
        return (
            sessionStorage?.wordListFilter ?
                sessionStorage.wordListFilter
                :
                ""
        )
    }

    const setWordListFilter = (value) => {
        sessionStorage.wordListFilter = value
    }    


    const hasUserInfo = () => {
        const userInfo = getUserInfo()
        if ("updateTime" in userInfo) {
            if (getElapsedTime(userInfo.updateTime) < 24 * 3600 * 1000) {
                return true
            }
        }
        return false
    }

    return (
        <UserContext.Provider
            value={{
                setUserInfo: (userInfo) => {
                    userInfo.updateTime = getNowInIso8601Jst()
                    setUserInfo(userInfo)
                },
                hasUserInfo: () => {
                    return hasUserInfo()
                },
                clearUserInfo: () => {
                    sessionStorage.removeItem("userInfo")
                    imageDB.items.clear()
                    wordDB.items.clear()
                },
                cleanOldItemsFromDBs: () => {
                    const now = new Date()
                    const dt = new Date(now.getTime() - 24 * 3600 * 1000)
                    const iso8601JstDateTime24hoursAgo = convertDateTimeInIso8601Jst(dt)
                    console.log(`iso8601JstDateTime24hoursAgo: ${iso8601JstDateTime24hoursAgo}`)
                    imageDB.items.where("updateTime").below(iso8601JstDateTime24hoursAgo).delete()
                    wordDB.items.where("updateTime").below(iso8601JstDateTime24hoursAgo).delete()
                },
                setUserSettings: (options) => {
                    const refinedOptions = {}
                    if ("quizMode" in options) {
                        refinedOptions.quizMode = options.quizMode
                    }
                    if ("fsrsRetention" in options) {
                        refinedOptions.fsrsRetention = options.fsrsRetention
                    }
                    if ("maxQuizCount" in options) {
                        refinedOptions.maxQuizCount = options.maxQuizCount
                    }

                    const userInfo = getUserInfo()
                    setUserInfo({
                        ...userInfo,
                        settings: {
                            ...userInfo.settings,
                            ...refinedOptions
                        }
                    })
                },
                getQuizMode: () => {
                    return getUserInfo().settings.quizMode
                },
                getFsrsRetention: () => {
                    return getUserInfo().settings.fsrsRetention
                },
                getMaxQuizCount: () => {
                    return getUserInfo().settings.maxQuizCount
                },
                getExpiredAt: () => {
                    return getUserInfo().settings.expiredAt
                },
                getDaysUntilExpire: () => {
                    return getUserInfo().settings.daysUntilExpire
                },
                getTotalWordCount: () => {
                    return getUserInfo().settings.totalWordCount
                },
                incrementTotalWordCount: () => {
                    const userInfo = getUserInfo()
                    setUserInfo({
                        ...userInfo,
                        settings: {
                            ...userInfo.settings,
                            totalWordCount: userInfo.settings.totalWordCount + 1
                        }
                    })
                },
                decrementTotalWordCount: () => {
                    const userInfo = getUserInfo()
                    setUserInfo({
                        ...userInfo,
                        settings: {
                            ...userInfo.settings,
                            totalWordCount: userInfo.settings.totalWordCount - 1
                        }
                    })
                },
                getWordCountQuota: () => {
                    return getUserInfo().settings.WordCountQuota
                },
                getTotalSentenceImageCount: () => {
                    return getUserInfo().settings.totalSentenceImageCount
                },
                setTotalSentenceImageCount: (count) => {
                    const userInfo = getUserInfo()
                    setUserInfo({
                        ...userInfo,
                        settings: {
                            ...userInfo.settings,
                            totalSentenceImageCount: count
                        }
                    })
                },
                getSentenceImageCountQuota: () => {
                    return getUserInfo().settings.SentenceImageCountQuota
                },
                getTotalQuizCount: () => {
                    return getUserInfo().settings.totalQuizCount
                },
                getTotalStudyTime: () => {
                    return getUserInfo().settings.totalStudyTime
                },
                getIsFirstRun: () => {
                    if ("isFirstRun" in getUserInfo().settings) {
                        return getUserInfo().settings.isFirstRun
                    } else {
                        return false
                    }
                },
                getUserInfo: () => {
                    return getUserInfo()
                },
                getUserId: () => {
                    return getUserInfo().userId
                },
                getUserName: () => {
                    return getUserInfo().displayName
                },
                getUserEmail: () => {
                    return getUserInfo().email
                },
                setUserStatus: (userStatus) => {
                    setUserStatus(userStatus)
                },
                clearUserStatus: () => {
                    setUserStatus(initialUserStatus)
                },
                hasUserStatusFetched: () => {
                    return !(getUserStatus()?.notYetUpdated)
                },
                getWordHistoryFilter: () => {
                    return getWordHistoryFilter()
                },
                setWordHistoryFilter: (value) => {
                    setWordHistoryFilter(value)
                },
                getWordListFilter: () => {
                    return getWordListFilter()
                },
                setWordListFilter: (value) => {
                    setWordListFilter(value)
                },
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext);