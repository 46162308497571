import React from "react";
import { 
    Box
} from "@chakra-ui/react";

const EmbeddedLink = (props) => {
    let sourceText = props.sourceText;
    const needLink = props?.needLink || props.needLink;
    const clickHandler = props.clickHandler;

    const elements = [];

    const pattern = RegExp('<link redirectWordId="(?<redirectWordId>[^"]+)">(?<linkedText>[^<]*)</link>', 'g');
    let match = pattern.exec(sourceText);

    while (match) {
        let matchReplacement = match.groups.linkedText;
        let redirectWordId = match.groups.redirectWordId;

        elements.push(<React.Fragment key={match.index}>{sourceText.slice(0, match.index)}</React.Fragment>);

        if (needLink) {
            matchReplacement = (
                <Box
                    key={redirectWordId}
                    onClick={() => {
                        clickHandler(redirectWordId);
                    }}
                    textDecoration="underline"
                    textDecorationStyle="dashed"
                    textDecorationColor="gray.400"
                    m="0px 0px 4px 0px"
                >
                    {matchReplacement}
                </Box>
            );
        } else {
            matchReplacement = <React.Fragment key={redirectWordId}>{match.groups.linkedText}</React.Fragment>;
        }
        elements.push(matchReplacement);

        sourceText = sourceText.slice(match.index + match[0].length);
        match = pattern.exec(sourceText);
    }
    if (sourceText) {
        elements.push(<React.Fragment key="remaining">{sourceText}</React.Fragment>);
    }

    return (
        <Box display="flex" flexWrap="wrap">
            {elements}
        </Box>
    )
};

export default EmbeddedLink;
