import React from "react"
import {
    Box,
    Link,
    Flex
} from '@chakra-ui/react'

const Footer = () => {

    const gradientStyle = {
        background: 'linear-gradient(to right, #4299e1, #00B5D8)'
    };


    return (
        <footer
            className="page-footer font-small blue pt-4"
            style={gradientStyle}
        >
            <Box 
                className="container-fluid text-center text-md-left"
                color="white"
                maxW="700px"
                fontSize="14px"
            >
                <Flex 
                    justifyContent="center"
                    gap="20px"
                >
                    <Link href="/terms-of-service">利用規約</Link>
                    <Link href="/privacy-policy">プライバシーポリシー</Link>
                </Flex>
            </Box>

            <Box 
                className="footer-copyright text-center py-3"
                color="white"
                fontSize="14px"
            >
                © 2024 Copyright:&nbsp;
                <Link 
                    href="https://eikatsu.study/"
                >
                    necolab
                </Link>
            </Box>

        </footer>
    )
}

export default Footer
