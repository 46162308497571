import React, { useState, useEffect } from 'react'
import {
    Image,
    Spinner,
    Box
} from '@chakra-ui/react'

import no_image_square from "../assets/icons/no_image_square.png"

const ImageElement = (props) => {

    const [existImage, setExistImage] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null)

    useEffect(() => {
        (async() => {
            try {
                const response = await fetch(props.url)
                setExistImage(response.ok)

                if (response.ok) {
                    const imageBlob = await response.blob()
                    if (imageBlob) {
                        setImageUrl(URL.createObjectURL(imageBlob))
                    }
                }
            } catch (error) {
                console.error(`error:`, error)
                setExistImage(false)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [props.url])


    return (
        existImage ?
            <Box>
                {isLoading && (
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                )}
                <Image 
                    key={props.url} 
                    src={imageUrl}
                    maxW={props.maxW}
                    maxH={props.maxH}
                    display={isLoading ? "none" : "block"}
                    fallbackSrc={no_image_square}
                />
            </Box>
            :
            <></>
    )
}

export default ImageElement