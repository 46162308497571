import React, { useEffect } from 'react';
import { useTimer } from "react-timer-hook"
import {
    Box,
    Flex,
} from "@chakra-ui/react";


const Timer = (props) => {
    // console.log(`props.timerFlag: ${props.timerFlag}`)
    const time = new Date()
    time.setSeconds(time.getSeconds() + props.timerSeconds)

    const {
        totalSeconds,
        seconds,
        minutes,
        restart,
        pause
    } = useTimer({
        expiryTimestamp: time,
        autoStart: true,
        onExpire: () => props.timeoutFunc()
    })

    useEffect(() => {
        const time = new Date()
        time.setSeconds(time.getSeconds() + props.timerSeconds)
        if (props?.timerFlag && props.timerFlag.startsWith("restart")) {
            restart(time)
        } else if (props.timerFlag.startsWith("pause")) {
            setTimeout(() => {
                pause()
            }, props.timerPauseDelay)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.timerFlag])

    return (
        <Flex
            fontSize="16px"
            width="100px"
        >
            <Box
                bgColor={totalSeconds > props.warningSeconds ? "gray.600" : "red.500"}
                color="white"
                p="0px 2px 2px 2px"
                width="25%"
                textAlign="center"
                borderRadius="md"
                m="1px"
            >
                {minutes.toString().padStart(2, '0')[0]}
            </Box>
            <Box
                bgColor={totalSeconds > props.warningSeconds ? "gray.600" : "red.500"}
                color="white"
                p="0px 2px 2px 2px"
                width="25%"
                textAlign="center"
                borderRadius="md"
                m="1px"
            >
                {minutes.toString().padStart(2, '0')[1]}
            </Box>

            <Box
                width="4px"
                textAlign="center"
                color={totalSeconds > props.warningSeconds ? "gray.600" : "red.500"}
            >
                :
            </Box>
            <Box
                bgColor={totalSeconds > props.warningSeconds ? "gray.600" : "red.500"}
                color="white"
                p="0px 2px 2px 2px"
                width="25%"
                textAlign="center"
                borderRadius="md"
                m="1px"
            >
                {seconds.toString().padStart(2, '0')[0]}
            </Box>
            <Box
                bgColor={totalSeconds > props.warningSeconds ? "gray.600" : "red.500"}
                color="white"
                p="0px 2px 2px 2px"
                width="25%"
                textAlign="center"
                borderRadius="md"
                m="1px"
            >
                {seconds.toString().padStart(2, '0')[1]}
            </Box>            
        </Flex>        
    )
}

export default Timer
  