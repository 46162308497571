import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Stack,
    Button,
    Center
} from "@chakra-ui/react";
import {
    ArrowBackIcon
} from "@chakra-ui/icons"

import HeaderComponent from "./HeaderComponent";

const HtmlDocumentPage = (props) => {

    const navigate = useNavigate();

    const [htmlContent, setHtmlContent] = useState("");
    useEffect(() => {
        // read a HTML file
        fetch(props.url)
          .then((response) => response.text())
          .then((text) => setHtmlContent(text));
        console.log(htmlContent)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);    

    return (
        <Center>
            <Stack
                // m="20px 0px 0px 0px"
                maxW="700px"
                margin="0%"
                textAlign="left"
                p="1"
                rounded="md"
                width="100%"                      
            >
                <HeaderComponent
                    setLaunchSubApp={props.setLaunchSubApp}
                    content={ <></> }
                />                  

                <Stack
                    m="20px 0px 0px 0px"
                    width="100%"
                    textAlign="center"
                >
                    <Center>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Center>
                    <Button
                        leftIcon={<ArrowBackIcon />}
                        colorScheme="blue"
                        variant="outline"
                        width="80px"
                        m="0px 0px 28px 28px"
                        onClick={() => navigate("/")}
                    >
                        戻る
                    </Button>

                </Stack>
            </Stack>
        </Center>
    )
}

export default HtmlDocumentPage