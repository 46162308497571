import {
    Box,
    useRadio
} from "@chakra-ui/react";

const RadioCard = (props) => {
    const { getInputProps, getRadioProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='2px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    // bg: 'blue.300',
                    // color: 'white',
                    borderColor: 'blue.400',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px="20px"
                py="3px"
            >
                {props.children}
            </Box>
        </Box>
    )
}

export default RadioCard