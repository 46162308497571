import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik";
import {
    Box,
    Button,
    FormControl,
    Input,
    Text,
    Select,
    HStack,
    Textarea,
    Image,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AspectRatio
} from "@chakra-ui/react";
import * as Yup from 'yup'
import '@splidejs/react-splide/css';

import useApi from "../../hooks/useApi"
import "../../App.css";
import {
    posEtoJ,
    getPoses,
    removeElementFromWord,
    setValueToWordById,
    appendElementToWord,
} from "../../WordDataFunction"
import { useUserContext } from "../../context/userContext";
import { removeLinkTags } from "../../CommonFunction"

import no_image_square from "../../assets/icons/no_image_square.png"

const EditDialog = (props) => {
    
    const {
        deleteWord,
        deleteSentenceImages
    } = useApi()

    const {
        decrementTotalWordCount,
        getSentenceImageCountQuota,
        getTotalSentenceImageCount
    } = useUserContext()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [previousImage, setPreviousImage] = useState({})

    const [exceedSentenceImageCountQuota, setExceedSentenceImageCountQuota] = useState(false)

    useEffect(() => {
        (async () => {
            setIsImageUpdated(false)
            if (props.displayWordParams.editDialogParams.isOpen) {
                setPreviousImage({})
                onOpen()
            } else {
                onClose()
            }

            // check if hitting a quota of number of sentence image files
            const totalSentenceImageCount = await getTotalSentenceImageCount()
            const sentenceImageCountQuota = await getSentenceImageCountQuota()
            const sentenceId = props.displayWordParams.editDialogParams?.idMap?.sentenceId
            const sentenceIdsHavingImage = props.displayWordParams.sentenceIdsHavingImage

            if (!sentenceIdsHavingImage.includes(sentenceId) 
                && totalSentenceImageCount >= sentenceImageCountQuota) {
                setExceedSentenceImageCountQuota(true)
                setButtonLabel(buttonLables.hitQuota)
                setFilenameLabel(filenameLabels.hitQuota)
            } else {
                setExceedSentenceImageCountQuota(false)
                setButtonLabel(buttonLables.chooseFile)
                setFilenameLabel(filenameLabels.chooseFile)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayWordParams.editDialogParams.isOpen])

    //////////////////////////////////////////////////////////
    // formik and yup settings
    const validationSchema = Yup.object({
        pronunciation: Yup.string().max(64, "64文字以内で入力してください"),
        meaning: Yup.string().max(64, "64文字以内で入力してください"),
        sentence: Yup.string().matches(/^[\u0a\u0d\u0020-\u007e\u00a1-\u0240]+$/, "英文字以外を入力しないでください").max(256, "256文字以内で入力してください"),
        sentenceMeaning: Yup.string().max(256, "256文字以内で入力してください"),
    })

    const formikInitialValues = {
        pronunciation: props.displayWordParams.pronunciation ? props.displayWordParams.pronunciation : "",
        pos: props.displayWordParams.editDialogParams.pos ? props.displayWordParams.editDialogParams.pos : "",
        meaning: props.displayWordParams.editDialogParams.meaning ? props.displayWordParams.editDialogParams.meaning : "",
        sentence: props.displayWordParams.editDialogParams.sentence ? props.displayWordParams.editDialogParams.sentence : "",
        sentenceMeaning: props.displayWordParams.editDialogParams.sentenceMeaning ? props.displayWordParams.editDialogParams.sentenceMeaning : ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validationSchema
    })

    //////////////////////////////////////////////////////////
    // processing an image
    const [isImageUpdated, setIsImageUpdated] = useState(false)

    const onImageFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            const id = props.displayWordParams.editDialogParams.idMap.sentenceId

            // previousImage is set only at the first time, not updated later
            if (Object.keys(previousImage).length === 0) {
                const image = id in props.userSentenceImages ? props.userSentenceImages[id] : null
                const repo = await props.imageRepo
                const type = id in repo ? repo[id].type : "abort"

                setPreviousImage({
                    id,
                    type,
                    image
                })
            }

            props.imageDispatch({
                id,
                file,
                type: "addFile"
            })

            setIsImageUpdated(true)
        }
        catch (err) {
            console.log(err);
        }        
    }
    //////////////////////////////////////////////////////////
    
    const PosControl = () => {

        const optionList = []
        const poses = getPoses()
        Object.keys(poses).forEach((pos) => {
            optionList.push(
                <option 
                    value={pos}
                    key={pos}
                    disabled={props.displayWordParams.inusedPoses.has(pos)}
                >
                    {poses[pos]}
                </option>
            )
        })
        optionList.push(<option value="" key={""} hidden={true} disabled={true}></option>)

        return (
            <FormControl
                width="108px"
            >
                <Select
                    as="select" 
                    id={"pos"}
                    {...formik.getFieldProps("pos")}
                    width="108px"
                    ml="8px"
                    value={formik.values.pos || ""}
                >
                    {optionList}
                </Select>
            </FormControl>
        )
    }

    //////////////////////////////////////////////////////////

    const disableSaveButton = (type, formik, previousImage) => {
        if (type === "pronunciationEdit") {
            if (!formik.errors.pronunciation) {
                if (formik.values.pronunciation !== props.displayWordParams.editDialogParams.pronunciation) {
                    return false
                }
            }
        } else if (type === "meaningEdit") {
            if (!formik.errors.meaning) {
                if (formik.values.pos !== props.displayWordParams.editDialogParams.pos
                    || formik.values.meaning !== props.displayWordParams.editDialogParams.meaning
                    || isImageUpdated) {
                    return false
                }
            }
        } else if (type === "sentenceEdit") {
            if (!formik.errors.sentence && !formik.errors.sentenceMeaning) {
                if (formik.values.sentence !== props.displayWordParams.editDialogParams.sentence
                    || formik.values.sentenceMeaning !== props.displayWordParams.editDialogParams.sentenceMeaning
                    || props.displayWordParams.editDialogParams.isImagaUpdated 
                    || isImageUpdated) {
                    return false
                }
            }
        } else if (type === "wordAdd") {
            if (!formik.errors.pronunciation && !formik.errors.pos && !formik.errors.meaning && !formik.errors.sentence && !formik.errors.sentenceMeaning) {
                if (formik.values.pos && formik.values.meaning) {
                    return false
                }
            }
        } else if (type === "meaningAdd") {
            if (!formik.errors.meaning && !formik.errors.sentence && !formik.errors.sentenceMeaning) {
                if (formik.touched.pos 
                    || formik.touched.meaning
                    || isImageUpdated) {
                    return false
                }
            }
        } else if (type === "sentenceAdd") {
            if (!formik.errors.sentence && !formik.errors.sentenceMeaning) {
                if (formik.touched.sentence
                    || isImageUpdated) {
                    return false
                }
            }
        } else if (["wordDelete", "meaningDelete", "sentenceDelete"].includes(props.displayWordParams.editDialogParams.type)) {
            return false
        }

        return true
    }

    //////////////////////////////////////////////////////////

    const splitElementKey = (elementType) => {
        let element = "";
        let type = "";

        (["Delete", "Add", "Edit"]).some((t) => {
            if (elementType.endsWith(t)) {
                element = elementType.substring(0, elementType.length - t.length)
                type = t
                return true
            } else {
                return false
            }
        })

        return [element, type]
    }

    //////////////////////////////////////////////////////////
    // for <Input type="file" />

    const buttonLables = {
        chooseFile: "ファイルを選択",
        hitQuota: "選択できません"
    }

    const filenameLabels = {
        chooseFile: "ファイルが選択されていません",
        hitQuota: "登録画像数が上限を超えました"
    }

    
    const inputRef = useRef(null)
    const [buttonLabel, setButtonLabel] = useState(buttonLables.chooseFile)
    const [filenameLable, setFilenameLabel] = useState(filenameLabels.chooseFile)

    const handleClick = () => {
        inputRef.current.click()
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFilenameLabel(file.name);
            onImageFileChange(e);
        }
    }
    
    //////////////////////////////////////////////////////////

    return (
        <Modal 
            closeOnOverlayClick={false}
            isOpen={isOpen} 
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.displayWordParams.editDialogParams.header}</ModalHeader>
                <ModalCloseButton 
                    onClick={() => {
                        props.dispatch({
                            type: "displayWordEditDialogCanceled",
                            data: {
                                previous:{
                                    search: props.searchParams
                                },
                                current: {
                                    wordData: props.displayWordParams.wordData,
                                    isEditted: props.displayWordParams.isEditted
                                }
                            }
                        })
                    }}
                />
                <ModalBody pb={6}>
                    <Stack>
                        {/* 単語 */}
                        {
                            ["meaningEdit", "sentenceEdit", "pronunciationEdit",
                                "wordAdd", "meaningAdd", "sentenceAdd",
                                "wordDelete"/*, "meaningDelete", "sentenceDelete"*/].includes(props.displayWordParams.editDialogParams.type) ?
                                <HStack>
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        単語
                                    </Box>
                                    <Box
                                        flex="1"
                                        fontSize="3xl"
                                    >
                                        {props.displayWordParams.displayName}
                                    </Box>
                                </HStack>
                                :
                                <></>
                        }

                        {/* 発音 */}
                        {
                            ["meaningEdit", "sentenceEdit", "pronunciationEdit",
                                "wordAdd", "meaningAdd", "sentenceAdd"].includes(props.displayWordParams.editDialogParams.type) ?
                                <HStack pb="10px">
                                    <Box 
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        発音
                                    </Box>
                                    <Box
                                        flex="1"
                                    >
                                    {
                                        [/* "meaningEdit", "sentenceEdit",*/ "pronunciationEdit",
                                            "wordAdd" /* "meaningAdd", "sentenceAdd"*/].includes(props.displayWordParams.editDialogParams.type) ?
                                            <Stack
                                                flex="1"
                                            >
                                                <Input 
                                                    id="pronunciation"
                                                    {...formik.getFieldProps("pronunciation")}
                                                    className="EditableText"
                                                    autoComplete="off"
                                                    placeholder="単語の発音を入力してください"
                                                />
                                                <Box
                                                    maxH="10px"
                                                    color="red"
                                                    textAlign="left"
                                                    ml="4"
                                                >
                                                    {
                                                        formik.errors.pronunciation ? (
                                                            formik.errors.pronunciation
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Box>                                            
                                            </Stack>
                                            :
                                            <>
                                                {props.displayWordParams.editDialogParams.pronunciation}
                                            </>
                                    }
                                    </Box>
                                </HStack>
                                :
                                <></>
                        }

                        {/* 品詞 */}
                        {
                            ["meaningEdit", "sentenceEdit", /* "pronunciationEdit" */
                                "wordAdd", "meaningAdd", "sentenceAdd",
                                /*"wordDelete", "meaningDelete", "sentenceDelete"*/].includes(props.displayWordParams.editDialogParams.type) ?

                                <HStack>
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        品詞
                                    </Box>
                                    {
                                        ["meaningEdit", /*"sentenceEdit", "pronunciationEdit" */
                                            "wordAdd", "meaningAdd"/*, "sentenceAdd"*/].includes(props.displayWordParams.editDialogParams.type) ?
                                            <PosControl/>
                                            :
                                            <>
                                                {posEtoJ(props.displayWordParams.editDialogParams.pos)}
                                            </>
                                    }
                                </HStack>
                                :
                                <></>
                        }

                        {/* 意味 */}
                        {
                            ["meaningEdit", "sentenceEdit", /* "pronunciationEdit" */
                                "wordAdd", "meaningAdd", "sentenceAdd",
                                /*"wordDelete",*/ "meaningDelete"/*, "sentenceDelete"*/].includes(props.displayWordParams.editDialogParams.type) ?
                                <HStack>
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        意味
                                    </Box>
                                    {
                                        ["meaningEdit", /*"sentenceEdit", "pronunciationEdit" */
                                        "wordAdd", "meaningAdd"/*, "sentenceAdd"*/].includes(props.displayWordParams.editDialogParams.type) ?
                                            <Stack
                                                flex="1"
                                            >
                                                <Input 
                                                    id={"meaning"}
                                                    {...formik.getFieldProps("meaning")}
                                                    autoComplete="off"
                                                    placeholder="単語の意味を入力してください"
                                                />
                                                <Box
                                                    maxH="10px"
                                                    color="red"
                                                    textAlign="left"
                                                    ml="4"
                                                >
                                                    {
                                                        formik.errors.meaning ? (
                                                            formik.errors.meaning
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Box>
                                            </Stack>
                                            :
                                            <Box
                                                fontSize="xl"
                                                pl="2px"
                                            >
                                                {removeLinkTags(props.displayWordParams.editDialogParams.meaning)}
                                            </Box>                            
                                    }
                                </HStack>
                                :
                                <></>
                        }
                        {/* 例文 */}
                        {
                            [/*"meaningEdit",*/ "sentenceEdit", /* "pronunciationEdit" */
                                "wordAdd", "meaningAdd", "sentenceAdd",
                                /*"wordDelete", "meaningDelete",*/ "sentenceDelete"].includes(props.displayWordParams.editDialogParams.type) ?
                                <HStack pb="3">
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        例文
                                    </Box>
                                    {
                                        [/*"meaningEdit",*/ "sentenceEdit", /* "pronunciationEdit" */
                                            "wordAdd", "meaningAdd", "sentenceAdd"].includes(props.displayWordParams.editDialogParams.type) ?
                                            <Stack
                                                flex="1"
                                            >
                                                <Textarea 
                                                    id={"sentence"}
                                                    {...formik.getFieldProps("sentence")}
                                                    className="EditableText"
                                                    placeholder="例文を入力してください"
                                                />
                                                <Box
                                                    maxH="10px"
                                                    color="red"
                                                    textAlign="left"
                                                    ml="4"
                                                >
                                                    {
                                                        formik.errors.sentence ? (
                                                            formik.errors.sentence
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Box>
                                            </Stack>
                                            :
                                            <>
                                                {props.displayWordParams.editDialogParams.sentence}
                                            </>
                                    }
                                </HStack>
                                :
                                <></>
                        }
                        {/* 例文 */}
                        {
                            [/*"meaningEdit",*/ "sentenceEdit", /* "pronunciationEdit" */
                                "wordAdd", "meaningAdd", "sentenceAdd",
                                /*"wordDelete", "meaningDelete",*/ "sentenceDelete"].includes(props.displayWordParams.editDialogParams.type) ?
                                <HStack>
                                    <Box 
                                        bgColor="blue.100"
                                        fontSize="xs"
                                        minW="30px"
                                        textAlign="center"
                                    >
                                        意味
                                    </Box>
                                    {
                                        [/*"meaningEdit",*/ "sentenceEdit", /* "pronunciationEdit" */
                                            "wordAdd", "meaningAdd", "sentenceAdd"].includes(props.displayWordParams.editDialogParams.type) ?
                                            <Stack
                                                flex="1"
                                            >
                                                <Textarea 
                                                    id={`sentenceMeaning`}
                                                    {...formik.getFieldProps(`sentenceMeaning`)}
                                                    placeholder="例文の意味を入力してください"
                                                />
                                                <Box
                                                    maxH="10px"
                                                    color="red"
                                                    textAlign="left"
                                                    ml="4"
                                                >
                                                    {
                                                        formik.errors.sentenceMeaning ? (
                                                            formik.errors.sentenceMeaning
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Box>
                                            </Stack>
                                            :
                                            <>
                                                {props.displayWordParams.editDialogParams.sentenceMeaning}
                                            </>
                                    }
                                </HStack>
                                :
                                <></>
                        }
                        {/* 例文の画像 */}
                        {
                            [/*"meaningEdit",*/ "sentenceEdit", /* "pronunciationEdit" */
                            "wordAdd", "meaningAdd", "sentenceAdd",
                            /*"wordDelete", "meaningDelete",*/ "sentenceDelete"].includes(props.displayWordParams.editDialogParams.type) ?
                                <>
                                    <Stack>
                                        <HStack>
                                            <Box 
                                                bgColor="blue.100"
                                                fontSize="xs"
                                                minW="30px"
                                                textAlign="center"
                                            >
                                                画像
                                            </Box>
                                            <Box
                                                flex="1"
                                            >
                                            <AspectRatio maxW="512px" ratio={1}>
                                                <Image 
                                                    src={props.userSentenceImages[props.displayWordParams.editDialogParams.idMap.sentenceId]}
                                                    width="100%"
                                                    height="100%"
                                                    fallbackSrc={no_image_square}
                                                />
                                            </AspectRatio>
                                            </Box>                                                   
                                        </HStack>
                                        <HStack
                                            w="100%"
                                        >
                                            <Button 
                                                onClick={handleClick}
                                                isDisabled={exceedSentenceImageCountQuota}
                                                w="140px"
                                            >
                                                {buttonLabel}
                                            </Button>
                                            <Box
                                                flex="1"
                                                overflow="hidden"
                                                m="14px 0px 0px 0px"
                                            >
                                                <Text isTruncated>{filenameLable}</Text>
                                            </Box>
                                            <Input 
                                                ref={inputRef}
                                                type="file"
                                                accept="image/*"
                                                display="none"
                                                onChange={handleFileChange}
                                            />                                
                                        </HStack>
                                    </Stack>
                                </>
                                :
                                <>
                                </>
                        }
                    </Stack>
                </ModalBody>
    
                <ModalFooter>
                    <Button
                        colorScheme={["wordDelete", "meaningDelete", "sentenceDelete"].includes(props.displayWordParams.editDialogParams.type) ? "red" : "blue"}
                        mr={3}
                        isDisabled={
                            disableSaveButton(props.displayWordParams.editDialogParams.type, formik, previousImage)
                        }
                        onClick={async () => {
                            const [element, type] = splitElementKey(props.displayWordParams.editDialogParams.type)
                            let values = {}
                            let idMap = props.displayWordParams.editDialogParams.idMap

                            let wordData = props.displayWordParams.wordData

                            if (type === "Delete") {
                                // choose images that should be deleted from backend
                                // NOTE: in case of wordDelete, props.imageDispatch is not be used, because imageRepo is not updated immediately
                                const deletedIds = []
                                wordData.meanings.forEach((meaning) => {
                                    meaning.sentences.forEach((sentence) => {
                                        if (sentence.origin === "user" && sentence?.hasImage) {
                                            if (element === "word" 
                                                    || (element === "meaning" && meaning.meaningId === idMap.meaningId) 
                                                    || (element === "sentence" && sentence.sentenceId === idMap.sentenceId)) {
                                                props.imageDispatch({
                                                    "id": sentence.sentenceId,
                                                    "type": "delete"
                                                })
                                                deletedIds.push(sentence.sentenceId)
                                            }
                                        }
                                    })
                                })

                                if (element === "word") {
                                    const requestId = crypto.randomUUID()
                                    props.setRequestId(requestId)

                                    try {
                                        if (deletedIds.length > 0) {
                                            await deleteSentenceImages(deletedIds, requestId)
                                        }

                                        await deleteWord(
                                            props.displayWordParams.wordId,
                                            props.displayWordParams.cId,
                                            props.displayWordParams.displayName,
                                            props.displayWordParams.selectedMeaning
                                        )
                                        decrementTotalWordCount()

                                    } catch (error) {
                                        console.log(`error: ${error}`)
                                    } finally {
                                        props.setRequestId(null)

                                        props.dispatch({
                                            type: "displayWordEditDialogCommitted",
                                            data: {
                                                previous:{
                                                    search: props.searchParams
                                                },
                                                current: {
                                                    subMode: "wordDelete"
                                                }
                                            }
                                        })
                                    }
                                } else {
                                    // check if the deleted meaning was selected
                                    if (element === "meaning"
                                        && idMap.meaningId === props.selectedMeaningId) {
                                        props.setSelectedMeaningId(null)
                                    }

                                    wordData = removeElementFromWord(
                                            wordData,
                                            element,
                                            props.displayWordParams.editDialogParams.idMap)

                                    props.dispatch({
                                        type: "displayWordEditDialogCommitted",
                                        data: {
                                            previous:{
                                                search: props.searchParams
                                            },
                                            current: {
                                                wordData
                                            }
                                        }
                                    })
                                }
                            } else {
                                if (type === "Add") {

                                    if (["meaning", "sentence"].includes(element)) {
                                        wordData = appendElementToWord(wordData, element, idMap)
                                    }
                    
                                    if (["word"].includes(element)) {
                                        values = {
                                            ...values,
                                            "pronunciation": formik.values.pronunciation
                                        }
                                    }
                    
                                    if (["word", "meaning"].includes(element)) {
                                        values = {
                                            ...values,
                                            "pos": formik.values.pos,
                                            "meaning": formik.values.meaning
                                        }
                                    }
                                    if (["word", "meaning", "sentence"].includes(element)) {
                                        values = {
                                            ...values,
                                            "sentence": formik.values.sentence,
                                            "sentenceMeaning": formik.values.sentenceMeaning
                                        }                    
                                    }

                                } else if (type === "Edit") {
                    
                                    if (["pronunciation"].includes(element)) {
                                        values = {
                                            ...values,
                                            "pronunciation": formik.values.pronunciation
                                        }
                                    }
                    
                                    if (["meaning"].includes(element)) {
                                        values = {
                                            ...values,
                                            "pos": formik.values.pos,
                                            "meaning": formik.values.meaning
                                        }
                                    }
                                    if (["sentence"].includes(element)) {
                                        values = {
                                            ...values,
                                            "sentence": formik.values.sentence,
                                            "sentenceMeaning": formik.values.sentenceMeaning
                                        }                    
                                    }                                    
                                }
                                
                                Object.keys(values).forEach((key) => {
                                    const value = values[key]
                                    switch (key) {
                                        case "pos":
                                        case "pronunciation":
                                        case "meaning":
                                        case "sentence":
                                        case "sentenceMeaning":
                                            wordData = setValueToWordById(wordData, key, value === undefined ? "" : value, idMap)
                                            break
                                        default:
                                            break
                                    }
                                })

                                const repo = await props.imageRepo
                                if (idMap && idMap.sentenceId in repo && repo[idMap.sentenceId] === "add") {
                                    wordData = setValueToWordById(wordData, "sentenceHasImage", true, idMap)
                                }

                                props.dispatch({
                                    type: "displayWordEditDialogCommitted",
                                    data: {
                                        previous:{
                                            search: props.searchParams
                                        },
                                        current: {
                                            wordData
                                        }
                                    }
                                })                                
                            }
                        }}
                    >
                        保存
                    </Button>
                    <Button 
                        onClick={() => {
                            const [, type] = splitElementKey(props.displayWordParams.editDialogParams.type)
                            if (["Add", "Edit"].includes(type) && Object.keys(previousImage).length > 0) {
                                if (previousImage.type === "add") {
                                    props.imageDispatch({
                                        id: previousImage.id,
                                        image: previousImage.image,
                                        type: "addImage"
                                    })
                                } else if (previousImage.type === "delete") {
                                    props.imageDispatch({
                                        id: previousImage.id,
                                        type: "delete"
                                    })
                                } else if (previousImage.type === "abort") {
                                    props.imageDispatch({
                                        id: previousImage.id,
                                        image: previousImage.image,
                                        type: "abort"
                                    })
                                }
                            }

                            props.dispatch({
                                type: "displayWordEditDialogCanceled",
                                data: {
                                    previous:{
                                        search: props.searchParams
                                    },
                                    current: {
                                        wordData: props.displayWordParams.wordData,
                                        isEditted: props.displayWordParams.isEditted
                                    }
                                }
                            })                         
                        }}
                    >
                        キャンセル
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EditDialog