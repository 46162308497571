/* eslint-disable no-control-regex */
import React, { useState, useReducer, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Textarea,
    HStack,
    Stack,
    VStack,
    Flex,
    Spacer,
    Spinner,
    IconButton
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons"
import { MdPlayArrow } from "react-icons/md";
import * as Yup from 'yup';
import { TypeAnimation } from 'react-type-animation';
import { AudioRecorder } from 'react-audio-voice-recorder'
import { useLocation } from 'react-router-dom';

import SoundEffect from "../SoundEffect";
import "../../App.css";
import {
    normalizeString,
    calcColorByScore
} from "../../CommonFunction"
import {
    posEtoJ
} from "../../WordDataFunction"
import CheckMark from "./CheckMark";
import useApi from "../../hooks/useApi"
import ImageElement from "../ImageElement";
import Timer from "../Timer"
import BackButton from "../BackButton";
import WordEpisode from "../WordEpisode";
import HeaderComponent from "../HeaderComponent";

const StudyWord = (props) => {
    const maxElapseSeconds = 180
    const warningSeconds = 30

    const typeAnimationSpeed = 50
    const {
        updateNotes,
        commitActivity,
        proofread,
        speechToText,
        isLoading
    } = useApi()
    const [disableNextButton, setDisableNextButton] = useState(true)
    const [requestIds, setRequestIds] = useState({
        speechToText: null,
        proofread: null
    })

    const toastTimerRef = useRef()
    const [timerFlag, setTimerFlag] = useState("")

 
    const displayNameRef = useRef()
    const proofreadButtonRef = useRef()
    const [proofReadComment, setProofreadComment] = useState("")
    const [startTime, setStartTime] = useState(new Date())
    const ignoreCommitActivityRef = useRef(false)

    /////////////////////////////////////////////////////////////////////
    // get a url parameter "isdebug"
    const query = new URLSearchParams(useLocation().search);
    const isdebug = query.get('isdebug');    

    ///////////////////////////////////////////////
    // for react-audio-voice-recorder

    const [sttNBest, setSttNBest] = useState(null)
    const requestSpeechToText = async(referenceText, base64Audio) => {
        const requestId = crypto.randomUUID()
        setRequestIds(
            {
                ...requestIds,
                speechToText: requestId
            }
        )
        const nBest = await speechToText(
            referenceText,
            base64Audio,
            requestId
        )

        if (nBest) {
            setRequestIds(
                {
                    ...requestIds,
                    speechToText: "complete"
                }
            )
            setSttNBest(nBest)
            return true
        } else {
            setRequestIds(
                {
                    ...requestIds,
                    speechToText: "complete"
                }
            )
            return false
        }
    }

    const readFileAsBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                resolve(reader.result.split(",")[1])
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    }

    const [sentenceAudioBlob, setSentenceAudioBlob] = useState(null)
    const addSentenceAudioElement = async (blob) => {
        const base64Audio = await readFileAsBase64(blob);
        setSentenceAudioBlob({ blob, base64Audio });
        checkPointDispatch("recordSentenceAudio");
    };

    const [wordAudioBlob, setWordAudioBlob] = useState(null)
    const addWordAudioElement = async (blob) => {
        const base64Audio = await readFileAsBase64(blob);
        setWordAudioBlob({ blob, base64Audio });
        checkPointDispatch("recordWordAudio");
    };    

    const wordAudioRef = useRef()
    const sentenceAudioRef = useRef()
    const playPartialAudio = (start, duration) => {
        sentenceAudioRef.current.currentTime = start / 10000000

        sentenceAudioRef.current.play()
        setTimeout(() => {
            sentenceAudioRef.current.pause()
        }, duration / 10000)
    }


    ///////////////////////////////////////////////

    ///////////////////////////////////////////////
    // timer location
    
    const [timerLocation, setTimerLocation] = useState(getTimerLocation())
    function getTimerLocation() {
        const bottom = 90
        const leftOffset = 220
        const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        const panelWidth = Math.min(viewportWidth, 810)
        let left = 0
        if (viewportWidth > panelWidth) {
            left = (viewportWidth - panelWidth) / 2 + panelWidth - leftOffset
        } else {
            left = viewportWidth - leftOffset
        }
        return {left, bottom}
    }
    window.addEventListener('load', (e) => {
        setTimerLocation(getTimerLocation())
        // console.log(`timerLeftLocation: ${JSON.stringify(timerLocation)}`)
    })
    window.addEventListener('resize', (e) => {
        setTimerLocation(getTimerLocation())
        // console.log(`timerLeftLocation: ${JSON.stringify(timerLocation)}`)
    })

    ///////////////////////////////////////////////


    const validationSchema = Yup.object({
        displayName: Yup.string().test("displayNameStartsWith", "スペルが間違っています", (value) => {
            return normalizeString(props.params.displayName)
                .startsWith(normalizeString(value))
        }),
        sentenceEn: Yup.string()
        .matches(/^[\u000a\u000d\u0020-\u007e\u00a1-\u0240]+$/,"英文字以外を入力しないでください")
            .required("英作文を入力してください。")
            .max(256, "256文字以内で入力してください")
    })

    const formik = useFormik({
        initialValues: {
            displayName: "",
            sentenceEn: "",
            notes: props.params.notes
        },
        validationSchema
    })

    const cIdRef = useRef("")
    const onSubmit = async () => {
        if (cIdRef.current !== props.params.cId) {
            cIdRef.current = props.params.cId
            if (formik.touched.notes) {
                const result = await updateNotes(props.params.cId, formik.values.notes)
                if (result) {
                    props.dispatch({ type: "studyComplete" })
                }
            } else {
                props.dispatch({ type: "studyComplete"})
            }

            formik.setFieldValue("displayName", "")
            formik.setFieldValue("sentenceEn", "")
            //formik.setFieldValue("notes", wordData.notes ? wordData.notes : "")
            formik.setFieldValue("notes", props.params.notes)
            setProofreadComment("")
            setRequestIds({
                proofread: null,
                speechToText: null
            })
            ignoreCommitActivityRef.current = false

            setWordAudioBlob(null)
            setSentenceAudioBlob(null)

            checkPointDispatch("reset")
        }
    }

    const clickFunc = (fieldStatus, element) => {
        if (element in fieldStatus) {
            fieldStatus = {
                ...fieldStatus,
                [element]: 1
            }
        } else if (element.startsWith("un") && element.substring(2) in fieldStatus) {
            fieldStatus = {
                ...fieldStatus,
                [element.substring(2)]: 0
            }
        } else if (element === "reset") {
            fieldStatus = Object.fromEntries(
                Object.entries(fieldStatus)
                    .map(([key, val]) => [key, 0])
            )
        }

        if (Object.keys(fieldStatus).length === Object.values(fieldStatus).reduce((sum, value) => { return sum + value })) {
            if (!ignoreCommitActivityRef.current) {
                let elapsedSeconds = Math.round(((new Date()) - startTime + proofReadComment.length * typeAnimationSpeed) / 1000)
                if (elapsedSeconds > maxElapseSeconds) {
                    elapsedSeconds = maxElapseSeconds
                }
                if (toastTimerRef.current) {
                    clearInterval(toastTimerRef.current)
                    toastTimerRef.current = null
                }

                commitActivity(
                    props.params.wordId,
                    props.params.cId,
                    "study",
                    props.params.displayName,
                    props.params.meaning,
                    {
                        elapsedSeconds 
                    }
                )
                // ))()
            }
            ignoreCommitActivityRef.current = true
            setStartTime(new Date())
            setDisableNextButton(false)
            setTimerFlag(`pause_${Math.random()}`)
        }

        return fieldStatus
    }
    const [fieldStatus, checkPointDispatch] = useReducer( 
        clickFunc,
        props.params.initialFieldStatus
    )

    const wordAudioList = 
        props.params.wordAudioUrls.map((u) =>
            <SoundEffect
                key={u}
                url={u}
                size={36}
                dispatch={() => { checkPointDispatch("listenWordAudio") }}
            />
        )

    const sentenceAudio = () => {
        return (
            props.params.sentenceAudioUrl ?
                <SoundEffect
                    url={props.params.sentenceAudioUrl}
                    size={36}
                    dispatch={() => { checkPointDispatch("listenSentenceAudio") }}
                />
                :
                <></>
        )
    }

    const editableTextBoxTheme = {
        color: "#000",
        backgroundColor: "#fff"
    };

    function getStepIndex(stepsAvailability, stepName) {
        const stepNames = Object.keys(stepsAvailability).filter(key => stepsAvailability[key]===true)
        return stepNames.findIndex((item) => item === stepName) + 1
    }

    useEffect(() => window.scrollTo(0, 0), [])

    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"            
        >
            <Flex
                height="28px"
                width="200px"
                position="fixed"
                zIndex="200"
                left={`${timerLocation.left}px`}
                bottom={`${timerLocation.bottom}px`}
            >
                <Spacer />
                <Timer
                    timerSeconds={maxElapseSeconds}
                    warningSeconds={warningSeconds}
                    timerFlag={timerFlag}
                    timerPauseDelay={proofReadComment.length * typeAnimationSpeed}
                    timeoutFunc={() => {}}
                />
            </Flex>

            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <Box
                        fontSize="4xl"
                        m="0px 0px 0px 4px"
                    >
                        {props.params.displayName}
                    </Box>
                }
            />            

                    <Box
                        m="0px 0px 0px 20px"
                    >
                        <HStack
                            fontSize="md"
                        >
                            <Box
                                bgColor="blue.100"
                                fontSize="xs"
                            >
                                品詞
                            </Box>
                            <Box>
                                {posEtoJ(props.params.pos)}
                            </Box>
                            {
                                props.params.pronunciation ?
                                    <>
                                        <Box
                                            bgColor="blue.100"
                                            pl="5px"
                                            fontSize="xs"
                                        >
                                            発音
                                        </Box>
                                        <Box>
                                            {props.params.pronunciation}
                                        </Box>
                                    </>
                                    :
                                    <></>
                            }
                        </HStack>
                        <HStack>
                            <Center
                                bgColor="blue.100"
                                fontSize="xs"
                            >
                                意味
                            </Center>
                            <HStack
                                flex="1"
                            >
                                {
                                    props.params.tags.map((tag, index) => {
                                        return (
                                            <Box
                                                bgColor="gray.400"
                                                textColor="white"
                                                textAlign="center"
                                                width="18px"
                                                height="18px"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                fontSize="md"
                                                key={index}
                                            >
                                                {tag}
                                            </Box>
                                        )
                                    })
                                }
                                <Box
                                    fontSize="2xl"
                                >
                                    {props.params.meaning}
                                </Box>
                            </HStack>
                        </HStack>

                        {
                            props.params.synonyms.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                            同義
                                    </Box>
                                    <Box 
                                        flex="1"
                                        fontSize="md"
                                        pb="3px"
                                    >
                                        {props.params.synonyms.join(", ")}
                                    </Box>                                        
                                </HStack>
                                :
                                <></>
                        }

                        {
                            props.params.usages.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        用例
                                    </Box>
                                    <Stack 
                                        flex="1"
                                        fontSize="md"
                                    >
                                        {
                                            props.params.usages.map((usage, index) => {
                                                return (
                                                    <Box 
                                                        key={index} 
                                                        pb="3px"
                                                    >
                                                        {usage}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Stack>                                        
                                </HStack>
                                :
                                <></>
                        }

                        {
                            props.params.compliments.length > 0 ?
                                <HStack
                                    mt="4px"
                                >
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        補足
                                    </Box>
                                    <Stack 
                                        flex="1"
                                        fontSize="md"
                                    >
                                        {
                                            props.params.compliments.map((compliment, index) => {
                                                return (
                                                    <Box 
                                                        key={index} 
                                                        pb="3px"
                                                    >
                                                        {compliment}
                                                    </Box>                                                        
                                                )
                                            })
                                        }
                                    </Stack>                                        
                                </HStack>
                                :
                                <></>
                        }                            

                    </Box>

            <Box
                rounded="md"
                border="0px 0px 10px 0px"
                borderColor="blue.100"
                padding={"0px 5px 5px 5px"}
            >
                

                <Divider />

                {
                    props.params.stepsAvailability.wordAudio ?
                        <Stack
                            pb="24px"
                        >
                            <HStack>
                                <Box
                                    width="36px"
                                >
                                    <CheckMark
                                        isDisabled={fieldStatus.listenWordAudio === 0 || fieldStatus.recordWordAudio === 0} 
                                    />
                                </Box>
                                <Box
                                    fontSize="xl"
                                >
                                    Step{getStepIndex(props.params.stepsAvailability, "wordAudio")}
                                </Box>
                            </HStack>
                            <Center>
                                <HStack>
                                    <Box>
                                        <HStack spacing={2}>
                                            {wordAudioList}
                                        </HStack>
                                    </Box>
                                    <Box
                                        pb="2px"
                                    >
                                        発音を聞きリピートしましよう
                                    </Box>
                                </HStack>
                            </Center>
                            <Stack>
                                <Center>
                                    <AudioRecorder
                                        onRecordingComplete={addWordAudioElement}
                                        audioTrackConstraints={{
                                            noiseSuppression: true,
                                            echoCancellation: true,
                                            // autoGainControl,
                                            // mimeType: "audio/webm; codecs=opus",
                                            channelCount: 1,
                                            audioBitsPerSecond: 256000,
                                            sampleRate: 16000
                                            // deviceId,
                                            // groupId,
                                            // sampleRate,
                                            // sampleSize,
                                        }}
                                        onNotAllowedOrFound={(err) => console.table(err)}
                                        downloadOnSavePress={false}
                                        mediaRecorderOptions={{
                                            // audioBitsPerSecond: 128000,
                                            audioBitsPerSecond: 16000,
                                        }}
                                        showVisualizer={true}
                                    />
                                </Center>
                                {
                                    wordAudioBlob ?
                                        <VStack>
                                            <Box>
                                                <audio
                                                    ref={wordAudioRef}
                                                    controls={false}
                                                    src={URL.createObjectURL(wordAudioBlob.blob)}
                                                />
                                            </Box>
                                            <IconButton
                                                    isRound={true}
                                                    variant='solid'
                                                    color='#63b3ed'
                                                    bgColor="white"
                                                    border="3px solid #63b3ed"                                                    
                                                    aria-label='play'
                                                    fontSize='32px'
                                                    icon={<MdPlayArrow />}                                                
                                                    onClick={()=>{
                                                        wordAudioRef.current.play()
                                                    }}
                                            />
                                        </VStack>
                                        :
                                        <></>
                                }
                            </Stack>
                        </Stack>
                        :
                        <></>
                }

                {
                    props.params.stepsAvailability.matchWordText ?
                        <FormControl>
                            <Stack
                                pb="24px"
                            >
                                <HStack>
                                    <Box
                                        width="36px"
                                    >
                                        <CheckMark 
                                            isDisabled={fieldStatus.matchWordText === 0} 
                                        />
                                    </Box>
                                    <Box
                                        fontSize="xl"
                                    >
                                        Step{getStepIndex(props.params.stepsAvailability, "matchWordText")}
                                    </Box>
                                </HStack>
                                <InputGroup
                                    size="lg"
                                >
                                    <Input
                                        id="displayName"
                                        ref={displayNameRef}
                                        autoComplete="off"
                                        style={editableTextBoxTheme}
                                        {...formik.getFieldProps("displayName")}
                                        onChange={(e) => {
                                            if (normalizeString(props.params.displayName) === normalizeString(e.target.value)) {
                                                // console.log("matchWordText")
                                                checkPointDispatch("matchWordText")
                                            } else {
                                                // console.log("unmatchWordText")
                                                checkPointDispatch("unmatchWordText")
                                            }
                                            formik.handleChange(e)
                                        }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            console.log("コピペ禁止！")
                                        }}
                                        placeholder="単語を正確に記述しましょう"
                                    />
                                    <InputRightElement>
                                        <CloseIcon
                                            onClick={() => {
                                                displayNameRef.current.value = ""
                                                formik.values.displayName = ""
                                                displayNameRef.current.focus()
                                            }}
                                            color='gray.300'
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <Box
                                    height="10px"
                                    color="red"
                                    textAlign="left"
                                    ml="4"
                                >
                                    {
                                        formik.errors.displayName ? (
                                            formik.errors.displayName
                                        )
                                            :
                                            null
                                    }
                                </Box>
                            </Stack>
                        </FormControl>
                        :
                        <></>
                }

                {
                    props.params.stepsAvailability.sentenceAudio ?
                        <Stack
                            pb="24px"
                        >
                            <HStack>
                                <Box
                                    width="36px"
                                >
                                    <CheckMark
                                        isDisabled={fieldStatus.listenSentenceAudio === 0 || fieldStatus.recordSentenceAudio === 0}
                                    />
                                </Box>
                                <Box
                                    fontSize="xl"
                                >
                                    Step{getStepIndex(props.params.stepsAvailability, "sentenceAudio")}
                                </Box>
                            </HStack>

                            <Center
                                pb="6px"
                            >
                                <HStack>
                                    <Box>
                                        {sentenceAudio()}
                                    </Box>
                                    <Box
                                        pl="5px"
                                    >
                                        例文を聞きリピートしましょう
                                    </Box>
                                </HStack>
                            </Center>

                            <Stack>
                                <Center>
                                    <AudioRecorder
                                        onRecordingComplete={addSentenceAudioElement}
                                        audioTrackConstraints={{
                                            noiseSuppression: true,
                                            echoCancellation: true,
                                            // autoGainControl,
                                            // mimeType: "audio/webm; codecs=opus",
                                            channelCount: 1,
                                            audioBitsPerSecond: 256000,
                                            sampleRate: 16000
                                            // deviceId,
                                            // groupId,
                                            // sampleRate,
                                            // sampleSize,
                                        }}
                                        onNotAllowedOrFound={(err) => console.table(err)}
                                        downloadOnSavePress={false}
                                        // mediaRecorderOptions={{
                                        //     audioBitsPerSecond: 16000,
                                        // }}
                                        showVisualizer={true}
                                    />
                                </Center>
                                {
                                    sentenceAudioBlob?.base64Audio ?
                                        <Center>
                                            <VStack
                                                m="0px 0px 10px 0px"
                                            >
                                                <Box>
                                                    <audio
                                                        ref={sentenceAudioRef}
                                                        controls={false}
                                                        src={URL.createObjectURL(sentenceAudioBlob.blob)}
                                                    />
                                                </Box>
                                                <HStack>
                                                    <Box
                                                        pt="10px"
                                                    >
                                                        <IconButton
                                                            isRound={true}
                                                            variant='solid'
                                                            color='#63b3ed'
                                                            bgColor="white"
                                                            border="3px solid #63b3ed"
                                                            aria-label='play'
                                                            fontSize='32px'
                                                            icon={<MdPlayArrow />}                                                
                                                            onClick={()=>{
                                                                sentenceAudioRef.current.currentTime = 0
                                                                sentenceAudioRef.current.play()
                                                            }}
                                                        />
                                                    </Box>
                                                    {
                                                        sentenceAudioBlob?.blob?.type.startsWith("audio/ogg;") ?
                                                            <Box>
                                                                <Button
                                                                    type="button"
                                                                    onClick={(e)=>{
                                                                        e.preventDefault()
                                                                        requestSpeechToText(props.params.sentence, sentenceAudioBlob.base64Audio)
                                                                    }}
                                                                    isDisabled={requestIds.speechToText !== null}
                                                                    m="10px 0px 0px 0px"
                                                                >
                                                                    {!sttNBest ? "発音を採点する" : "発音採点済み"}
                                                                </Button>
                                                            </Box>
                                                            :
                                                            <></>
                                                    }
                                                </HStack>
                                                {
                                                    sttNBest ?
                                                        <VStack>
                                                            <HStack
                                                                color={calcColorByScore(sttNBest.AccuracyScore)}
                                                            >
                                                                <Box>
                                                                    あなたの発音:
                                                                </Box>
                                                                <Box
                                                                    fontSize="3xl"
                                                                    pb="4px"
                                                                >
                                                                    {sttNBest.AccuracyScore}
                                                                </Box>
                                                                <Box>
                                                                    点
                                                                </Box>
                                                            </HStack>
                                                            <Flex
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                wrap="wrap"
                                                            >
                                                                {
                                                                    sttNBest.Words.map((item, index) => {
                                                                        return (
                                                                            <VStack
                                                                                key={index}
                                                                                m="2px 4px 2px 4px"
                                                                                onClick={() => {
                                                                                    playPartialAudio(item.Offset, item.Duration)
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    textAlign="center"
                                                                                    minW="28px"
                                                                                    fontSize="md"
                                                                                >
                                                                                    {item.Word}
                                                                                </Box>
                                                                                <Box
                                                                                    bgColor={calcColorByScore(item.AccuracyScore)}
                                                                                    color="white"
                                                                                    fontSize="xs"
                                                                                    width="100%"
                                                                                    textAlign="center"
                                                                                >
                                                                                    {item.AccuracyScore}
                                                                                </Box>
                                                                            </VStack>
                                                                        )

                                                                    })
                                                                }

                                                            </Flex>
                                                        </VStack>
                                                        :
                                                        isLoading(requestIds.speechToText) ?
                                                            <VStack
                                                                width="100%"
                                                            >
                                                                <Spinner
                                                                    thickness='4px'
                                                                    speed='0.65s'
                                                                    emptyColor='gray.200'
                                                                    color='blue.500'
                                                                    size='xl'
                                                                />
                                                            </VStack>
                                                            :
                                                            <></>
                                                }
                                            </VStack>
                                        </Center>
                                        :
                                        <></>
                                }
                            </Stack>

                            <HStack
                                pb="6px"
                            >
                                <Box
                                    bgColor="blue.100"
                                    fontSize="xs"
                                >
                                    例文
                                </Box>
                                <Box flex="1">
                                    {props.params.sentence}
                                </Box>
                            </HStack>
                            <HStack
                                pb="16px"
                            >
                                <Box
                                    bgColor="blue.100"
                                    fontSize="xs"
                                >
                                    意味
                                </Box>
                                <Box
                                    flex="1"
                                >
                                    {props.params.sentenceMeaning}
                                </Box>
                            </HStack>
                            <Center
                                pb="6px"
                            >
                                <ImageElement
                                    url={props.params.sentenceImageUrl}
                                    maxW="200px"
                                    maxH="200px"
                                />
                            </Center>
                        </Stack>
                        :
                        <></>
                }

                {
                    props.params.stepsAvailability.writingText ?
                        <FormControl>
                            <Stack
                                pb="24px"
                            >
                                <HStack>
                                    <Box
                                        width="36px"
                                    >
                                        <CheckMark 
                                            isDisabled={fieldStatus.writingText === 0} 
                                        />
                                    </Box>
                                    <Box
                                        fontSize="xl"
                                    >
                                        Step{getStepIndex(props.params.stepsAvailability, "writingText")}
                                    </Box>
                                </HStack>

                                <HStack>
                                    <Box
                                        bgColor="blue.100"
                                        fontSize="xs"
                                    >
                                        例文
                                    </Box>
                                    <Box flex="1">
                                        {props.params.anotherSentenceMeaning}
                                    </Box>
                                </HStack>

                                <Textarea
                                    type="button"
                                    id="sentenceEn"
                                    autoComplete="off"
                                    size="lg"
                                    style={editableTextBoxTheme}
                                    {...formik.getFieldProps("sentenceEn")}
                                    placeholder={`"${props.params.displayName}" を使って例文を英語にしてみましょう`}
                                    onPaste={(e) => {
                                        e.preventDefault()
                                        console.log("コピペ禁止！")
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.nativeEvent.isComposing
                                            || e.key !== "Enter"
                                            || formik.errors.sentenceEn
                                            || formik.values.sentenceEn.length === 0) {
                                            return
                                        } else {
                                            e.preventDefault()
                                            proofreadButtonRef.current.click()
                                        }
                                    }}
                                />
                                <Box
                                    height="10px"
                                    color="red"
                                    textAlign="left"
                                    m="0px 0px 12px 4px"

                                >
                                    {
                                        formik.errors.sentenceEn ? (
                                            formik.errors.sentenceEn
                                        )
                                            :
                                            null
                                    }
                                </Box>
                                <Box
                                    mb="12px"
                                >
                                    <Button
                                        ref={proofreadButtonRef}
                                        colorScheme="blue"
                                        isDisabled={!formik.values.sentenceEn || formik.errors.sentenceEn || requestIds.proofread !== null}
                                        onClick={async () => {
                                            const requestId = crypto.randomUUID()
                                            setRequestIds({
                                                ...requestIds,
                                                proofread: requestId
                                            })
                                            const responseText = await proofread(
                                                props.params.displayName,
                                                props.params.anotherSentenceMeaning,
                                                formik.values.sentenceEn,
                                                requestId
                                            )

                                            if (responseText) {
                                                setRequestIds({
                                                    ...requestIds,
                                                    proofread: "complete"
                                                })
                                                setProofreadComment(responseText)
                                            } else {
                                                setRequestIds({
                                                    ...requestIds,
                                                    proofread: "complete"
                                                })                                                
                                            }

                                            checkPointDispatch("writingText")
                                        }}
                                    >
                                        送信
                                    </Button>
                                </Box>
                                <Box>
                                {
                                    requestIds.proofread !== null ?
                                        requestIds.proofread === "complete" ?
                                            proofReadComment ?
                                                <>
                                                    <HStack
                                                        mb="6px"
                                                    >
                                                        <Box
                                                            bgColor="blue.100"
                                                            fontSize="xs"
                                                        >
                                                            AIによる添削
                                                        </Box>
                                                        <Spacer />
                                                    </HStack>
                                                    <Box
                                                        color="blue"
                                                    >
                                                        <TypeAnimation
                                                            sequence={
                                                                [proofReadComment]
                                                            }
                                                            wrapper="span"
                                                            speed={typeAnimationSpeed}
                                                        />
                                                    </Box>

                                                </>
                                                :
                                                <>
                                                    添削データの送信に失敗しました。申し訳ありません。
                                                </>
                                            :
                                            <Box
                                                width="100%"
                                                display="flex"
                                                justifyContent="center"
                                            >
                                                <Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='blue.500'
                                                    size='xl'
                                                />
                                            </Box>
                                        :
                                        <></>
                                }
                                </Box>
                            </Stack>
                        </FormControl>
                        :
                        <></>
                }

                <Divider />

                {
                    props.params.episode ?
                        <>
                        <WordEpisode
                            displayName={props.params.displayName}
                            imageUrl={props.params.wordImageUrl}
                            episode={props.params.episode}
                        />
                        {
                            isdebug ?
                                <Flex
                                    m="10px 0px 10px 0px"
                                >
                                    <Spacer/>
                                    <Button
                                        fontSize="xs"
                                        height="24px"
                                        onClick={() => {
                                            navigator.clipboard.writeText(`D:\\Temp\\data\\episodeDebug\\2024-04-04\\${props.params.wordId}.txt`)
                                        }}
                                    >
                                        copy the path
                                    </Button>
                                    
                                </Flex>
                                :
                                <></>
                        }
                        </>
                        :
                        <></>
                }                

                <Box
                    pb="6px"
                >
                    <FormControl>
                        <FormLabel htmlFor="notes">メモ欄</FormLabel>
                        <Textarea
                            id="notes"
                            {...formik.getFieldProps("notes")}
                            style={editableTextBoxTheme}
                            placeholder="この単語に関するメモが残せます"
                        />
                    </FormControl>
                </Box>
            </Box>

            <Box
                ml="5px"
            >
                <Button
                    id="nextButton"
                    isDisabled={disableNextButton}
                    onClick={onSubmit}
                    colorScheme="blue"
                    width="100px"
                >
                    学習完了
                </Button>
            </Box>

            <Box
                m="20px 0px 20px 5px"
            >
                <BackButton
                    dispatch={props.dispatch}
                />
            </Box>

        </Stack>
    )
}

export default StudyWord
