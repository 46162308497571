export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
        // knownAuthorities: ["accounts.google.com", "login.microsoftonline.com"],
        redirectUri: window.location.origin.includes('localhost') ?
            process.env.REACT_APP_MSAL_REDIRECT_URL_LOCAL
            :
            process.env.REACT_APP_MSAL_REDIRECT_URL
        ,
        postLogoutRedirectUri: window.location.origin.includes('localhost') ?
            process.env.REACT_APP_MSAL_REDIRECT_URL_LOCAL
            :
            process.env.REACT_APP_MSAL_REDIRECT_URL
        ,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        // cacheLocation: 'sessionStorage',
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
}

export const apiRequest = {
    url: process.env.REACT_APP_API_REQUEST_URL,
    scopes: [process.env.REACT_APP_API_REQUEST_SCOPE]
}

export const authRequest = {
    url: process.env.REACT_APP_AUTH_TOKENS_URL
}

export const loginRequest = {
    scopes: ["openid", "profile", "email"], 
    redirectUri: window.location.origin.includes('localhost') ?
        process.env.REACT_APP_LOGIN_REQUEST_URL_LOCAL
        :
        process.env.REACT_APP_LOGIN_REQUEST_URL
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */ 
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};