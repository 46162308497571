import React, { useRef } from "react"
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title
} from "chart.js";
import { Chart } from "react-chartjs-2";

const LearningChart = (props) => {

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController,
        Title
    );

    /*
    * Y軸の最大値の調整
    */
    const calculateYAxisMax = (value) => {
        // 値に対する補正を行う
        let adjustedValue =
            value >= 0 ? Math.ceil(value * 1.05) : Math.floor(value * 0.95);

        // 1桁の場合の処理
        if (Math.abs(value) < 10) {
            return value >= 0 ? (value < 5 ? 0 : 5) : value > -5 ? -5 : -10;
        }

        // 2桁の場合は5の倍数に四捨五入（負の数の場合は特別な処理）
        if (Math.abs(adjustedValue) >= 10 && Math.abs(adjustedValue) < 100) {
            if (adjustedValue < 0) {
            // 負の数の場合は前の「5で割り切れるより大きい数」にする
            return Math.floor(adjustedValue / 5) * 5;
            }
            // 正の数の場合は次の「5で割り切れるより大きい数」にする
            return Math.ceil(adjustedValue / 5) * 5;
        }

        // 桁数に応じて四捨五入し、整数でキリのいい数字にする
        const magnitude = Math.pow(
            10,
            Math.floor(Math.log10(Math.abs(adjustedValue))) -
            (Math.abs(adjustedValue) < 1000 ? 1 : 2)
        );
        return Math.round(adjustedValue / magnitude) * magnitude;
    };

    let accuracyMax = 100;

    let quizCountMax = 0;
    if (props.quizHistory.quizCountList) {
        // 最大値の算出
        quizCountMax = Math.max(...props.quizHistory.quizCountList);
        quizCountMax = calculateYAxisMax(quizCountMax);
    }

    const data = {
            /** 1. x軸のラベルの配列: 日付ラベル */
        labels: props.quizHistory.dateList,
        /** Chart に表示する DataSet */
        datasets: [
            // 2. y軸・左: 「回答数」=> 折れ線グラフ
            {
                /** 折れ線グラフ */
                type: "line",
                label: "正解率",
                backgroundColor: "#4299e1",
                borderColor: "#4299e1",
                borderWidth: 1,
                fill: false,
                data: props.quizHistory.accuracyList,
                yAxisID: "yleft", // optionsで設定したIDを指定
            },
            // 3. y軸・右: 「正解率」=> 棒グラフ
            {
                /** 棒グラフ */
                type: "bar",
                label: "総回答数",
                backgroundColor: "#76E4F7",
                borderColor: "#76E4F7",
                borderWidth: 1,
                data: props.quizHistory.quizCountList,
                /** y軸・右 */
                yAxisID: "yright",
            },
        ],
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            // onComplete: () => {
            //     if (chartRef.current?.data 
            //         && chartRef.current.data?.labels 
            //         && props.quizHistory.dateList.length > 0
            //         && chartRef.current.data.labels.length === props.quizHistory.dateList.length
            //         && !isDisplayed
            //     ) {

            //         const lastIndex = props.quizHistory.dateList.length - 1
            //         const lastDataPoints = Array.from({ length: 2 }, (_, datasetIndex) => ({
            //             datasetIndex,
            //             index: lastIndex,
            //         }));

            //         chartRef.current.tooltip.setActiveElements(lastDataPoints)
            //         chartRef.current.tooltip.position = { 
            //             x: chartRef.current.scales.x.getPixelForValue(lastIndex),
            //             y: chartRef.current.scales.yleft.getPixelForValue(props.quizHistory.quizCountList[lastIndex]) 
            //         };
            //         chartRef.current.tooltip.update();
            //         chartRef.current.update();
            //         setIsDisplayed(true)
            //     }
            // }
        },
        scales: {
            /** x軸 */
            x: {
                stacked: false,
            },
            /** yleft (y軸・左): Y軸が、複数あるので yleft と yright のように軸にIDを付ける */
            yleft: {
                stacked: false,
                max: accuracyMax,
                min: 0,
            },
            /** yright (y軸・右): Y軸が、複数あるので yleft と yright のように軸にIDを付ける */
            yright: {
                stacked: false,
                position: "right",
                max: quizCountMax,
                min: 0,
            },
        },
        interaction: {
            /** 積み上げ実績 & 該当値 を合わせて表示する */
            mode: "index",
            intersect: false,
        },
        plugins: {
            /** タイトル設定 */
            title: {
                display: false,
            },
            /** ツールチップ設定 */
            tooltip: {
                backgroundColor: "rgba(255,255,255,0.8)",
                titleColor: "#1a1826",
                titleSpacing: 10,
                bodyColor: "#1a1826",
                bodySpacing: 10,
                displayColors: true,
                borderColor: "#afaeb3",
                borderWidth: 1,
                padding: 12,
                titleFont: {
                    size: 18,
                },
                bodyFont: {
                    size: 18,
                },
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            if (label.endsWith("正解率: ")) {
                                if (context.raw === 0) {
                                    label += `ー`
                                } else {
                                    label += `${context.raw}%`
                                }

                            } else {
                                label += context.raw
                            }
                        }
                        return label;
                    }
                }
            },
        }       
    };

    /////////////////////////////////////////////////////////////////
    const chartRef = useRef(null);

    /** 折れ線 & 棒グラフの Chart */
    return (
        <div
            style={{
                height: "300px",
                width: "240px"
            }}
        >
            <Chart
                type={"bar"}
                data={data}
                options={options}
                ref={chartRef}
            />
        </div>
    );
};

export default LearningChart;