import React, { useRef } from "react";
import {
    Button,
    Input,
    InputGroup,
    InputRightElement,
    HStack
} from "@chakra-ui/react"
import { CloseIcon } from "@chakra-ui/icons"

const SearchBox = (props) => {
    // < required props >
    // inputId
    // formik
    // placeholder
    // buttonLable
    // onClickFunc
    // 
    // < optional props >
    // onFocusFunc
    // onBlurFunc
    // allowEmptyInput

    const ref = useRef()

    return (
        <HStack>
            <InputGroup
                size="md"
            >
                <Input
                    id={props.inputId}
                    ref={ref}
                    autoComplete="off"
                    {...props.formik.getFieldProps(props.inputId)}
                    defaultValue={props.query}
                    style={{
                        "color": "#000",
                        "backgroundColor": "#fff"
                    }}
                    onFocus={() => {
                        if (props.onFocusFunc !== undefined) {
                            props.onFocusFunc()
                        }
                    }}
                    onBlur={() => {
                        if (props.onBlurFunc !== undefined) {
                            props.onBlurFunc()
                        }
                    }}
                    placeholder={props.placeholder}
                    onKeyDown={(e) => {
                        if (e.nativeEvent.isComposing
                            || e.key !== "Enter"
                            || props.formik.errors[props.inputId]
                            || (!props.allowEmptyInput && !props.formik.values[props.inputId])) {
                            return
                        } else {
                            props.onClickFunc()
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                />
                <InputRightElement>
                    <CloseIcon
                        onClick={() => {
                            props.formik.setFieldValue(props.inputId, "")
                            props.formik.setTouched({[props.inputId]: ""})
                            ref.current.focus()
                        }}
                        color='gray.300'
                    />
                </InputRightElement>
            </InputGroup>
            <Button
                colorScheme="blue"
                isDisabled={
                    (!props.allowEmptyInput && !props.formik.values[props.inputId])
                        || props.formik.errors[props.inputId]
                }
                _disabled={{
                    bg: "blue.500",
                    color: "white",
                }}
                style={{
                    width: "100px"
                }}
                onClick={() => props.onClickFunc()}
                ref={props.buttonRef}
                m="0px 4px 0px 0px"
            >
                {props.buttonLabel}
            </Button>
        </HStack>
    )
}

export default SearchBox