import checkmark from "../../assets/icons/checkmark.png"
import uncheckmark from "../../assets/icons/uncheckmark.png"


const CheckMark = (props) => {
    return (
        props.isDisabled ?
            <img key={Math.random()} src={uncheckmark} width="50px" heigh="50px" alt="未完"/>
            :
            <img key={Math.random()} src={checkmark} width="50px" heigh="50px" alt="完了"/>
    )
}

export default CheckMark