import { createContext, useContext, useState } from "react";

const ErrorContext = createContext(undefined)

export const ErrorProvider = ({ children }) => {

    const initErrorMessage = {
        title: "",
        messages: []
    }
    const [errorMessage, setErrorMessage] = useState(initErrorMessage)

    return (
        <ErrorContext.Provider
            value={{
                errorMessage,
                clearErrorMessage: () => {
                    setErrorMessage(initErrorMessage)
                },
                setErrorMessage: (_errorMessage) => {
                    setErrorMessage({
                        ..._errorMessage
                    })
                }
            }}
        >
            {children}
        </ErrorContext.Provider>
    )
}

export const useErrorContext = () => useContext(ErrorContext);