import React, { useRef } from "react";
import {
    Button,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    Stack,
    HStack,
    Input,
    Image,
    Text,
    AspectRatio,
    Spacer,
    useDisclosure
} from '@chakra-ui/react';
import Resizer from "react-image-file-resizer";
import { MdOutlinePortrait } from "react-icons/md";
import DeleteAccountDialog from "./DeleteAccountDialog";

const AvatarImageDialog = (props) => {
    const { 
        isOpen: isDeleteAccountOpen,
        onOpen: onDeleteAccountOpen,
        onClose: onDeleteAccountClose
    } = useDisclosure();

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                "JPEG",
                100,
                0,
                uri => resolve(uri),
                "base64"
            );
        });

    const onImageFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                props.setPrevImageData(props.imageData); // Preserve current imageData
                const resizedImage = await resizeFile(file);
                props.setImageData(resizedImage); // Update parent state
                props.setIsImageUpdated(true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const closeButtonRef = useRef();

    return (
        <>
            <DeleteAccountDialog
                isOpen={isDeleteAccountOpen}
                onOpen={onDeleteAccountOpen}
                onClose={onDeleteAccountClose}
            />
            <Popover
                placement='bottom'
                closeOnBlur={false}
                trigger="click"
            >
                <PopoverTrigger>
                    <IconButton 
                        aria-label="プロファイル画像変更"
                        colorScheme="blue"
                        icon={<
                                MdOutlinePortrait
                                style={{ width: '24px', height: '24px' }}
                                color="white"
                            />
                        }
                        size="xs"
                    />
                </PopoverTrigger>
                <PopoverContent
                    color='white'
                    bg='blue.700'
                    borderColor='blue.700'
                    border="1px solid white"
                >
                    <PopoverHeader pt={4} fontWeight='bold' border='0'>
                        プロファイル画像
                    </PopoverHeader>
                    <PopoverArrow bg='blue.700' />
                    <PopoverCloseButton 
                        ref={closeButtonRef}
                    />
                    <Stack>
                        {props.imageData ? (
                            <AspectRatio maxW="150px" ratio={1}>
                                <Image src={props.imageData} />
                            </AspectRatio>
                        ) : (
                            <Text mx={4}>画像がありません</Text>
                        )}
                    </Stack>
                    <Stack>
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={onImageFileChange}
                        />
                    </Stack>
                    <HStack>
                        <Button
                            colorScheme="blue"
                            m="4px 0px 2px 8px"
                            width={14}
                            height={7}
                            mt={2}
                            mb={2}
                            onClick={() => closeButtonRef.current.click()}
                        >
                            更新
                        </Button>
                        <Button
                            colorScheme="gray"
                            m="4px 0px 2px 0px"
                            width={24}
                            height={7}
                            mt={2}
                            mb={2}
                            onClick={() => {
                                if (props.prevImageData) {
                                    props.setImageData(props.prevImageData);
                                    props.setPrevImageData(null);
                                }
                                props.setIsImageUpdated(false)
                                closeButtonRef.current.click()
                            }}
                        >
                            キャンセル
                        </Button>
                        <Spacer />
                        <Button
                            colorScheme="red"
                            m="4px 4px 2px 0px"
                            width={32}
                            height={7}
                            mt={2}
                            mb={2}
                            onClick={onDeleteAccountOpen}
                        >
                            アカウント削除
                        </Button>
                    </HStack>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default AvatarImageDialog;