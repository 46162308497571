import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Stack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,    
    HStack,
    Divider
} from "@chakra-ui/react";
import "../../App.css";
import useApi from "../../hooks/useApi"
import { useUserContext } from "../../context/userContext";
import { useFullCoverSpinnerContext } from "../../context/fullCoverSpinnerContext"

const SearchResultCandidate = (props) => {

    const { 
        readWordByWordId
    } = useApi()

    const {
        getTotalWordCount,
        getWordCountQuota
    } = useUserContext()    

    const {
        openRequest,
        closeRequest
    } = useFullCoverSpinnerContext()

    const [candidateIndex, setCandidateIndex] = useState(-1)

    useEffect(() => {
        setCandidateIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.query])

    const exceedWordCountQuota = getTotalWordCount() >=  getWordCountQuota()
    
    return (
        <Accordion 
            allowToggle={true}
            defaultIndex={[0]}
            width="100%"
            maxW="700px"
            mb="16px"
            index={candidateIndex}
            onChange={(index) => setCandidateIndex(index)}
        >
            <AccordionItem
                border="1px"
                borderRadius="md"
                borderColor="gray.300"
            >
                <h2>
                    <AccordionButton
                        _hover={{ bg: "blue.200" }}
                        bgColor="blue.100"
                    >
                        <Box>
                            検索結果候補
                        </Box>
                        <AccordionIcon/>
                    </AccordionButton>

                </h2>
                <AccordionPanel>
                    <Stack>
                        {
                            props.candidates.length === 0 ?
                                <Box
                                    ml="20px"
                                    color="gray.600"
                                >
                                    該当する検索結果はありません
                                </Box>
                                :
                                props.candidates.map((candidate, index) => {
                                    return (
                                        <>
                                            {
                                                candidate.matchStatus === "none" && !exceedWordCountQuota ?
                                                    <Divider
                                                        m="0px"
                                                    />
                                                    :
                                                    <></>
                                            }
                                            <Box
                                                border="1px"
                                                borderRadius="md"
                                                borderColor="gray.300"
                                                width="100%"
                                                m="0px"
                                                p="4px 10px 4px 10px"
                                                bgColor={candidate.isSelected ? "blue.100" : "white"}
                                                onClick={async () => {
                                                    if (candidate.matchStatus !== "none" || !exceedWordCountQuota) {
                                                        let requestId;
                                                        Promise.resolve()
                                                            .then(() => requestId = openRequest())
                                                            .then(() => readWordByWordId(
                                                                candidate.wordId,
                                                                candidate.displayName
                                                            ))
                                                            .then((wordData) => {
                                                                if (wordData) {
                                                                    const candidates = props.candidates.map((c) => {
                                                                        c.isSelected = c.wordId === candidate.wordId
                                                                        return c
                                                                    })
        
                                                                    props.dispatch({
                                                                        type: "search",
                                                                        data: {
                                                                            previous: {
                                                                                query: props.query,
                                                                                searchResult: {
                                                                                    selectedWordData: wordData,
                                                                                    candidates
                                                                                }
                                                                            },
                                                                            current: {}
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                            .catch((error) => console.log(error))
                                                            .finally(() => closeRequest(requestId))
                                                    }
                                                }}
                                            >
                                                {
                                                    candidate.matchStatus === "none" ?
                                                        <Box
                                                            width="100%"
                                                        >
                                                            {exceedWordCountQuota ? `"${candidate.displayName}" は見つかりませんでした` : `"${candidate.displayName}" を新規登録`}
                                                        </Box>
                                                        :
                                                        <HStack>
                                                            <Box
                                                                w="100px"
                                                                overflow="hidden"
                                                                display="flex"
                                                            >
                                                                <Text
                                                                    isTruncated
                                                                    m="0px"
                                                                >
                                                                    {candidate.displayName}
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                width="50%"
                                                                overflow="hidden"
                                                                flex="1"
                                                            >
                                                                <Text 
                                                                    isTruncated
                                                                    m="0px"
                                                                >
                                                                    {candidate.meaning}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                }
                                            </Box>
                                        </>
                                    )
                                })
                        }
                    </Stack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default SearchResultCandidate