import React, { useMemo } from "react"
import './App.css';
import { Routes, Route } from "react-router-dom"
import PageController from "./components/PageController"
import HtmlDocumentPage from "./components/HtmlDocumentPage"
import SignOutMainPage from "./components/SignOutMainPage"
import SignInPage from "./components/SignInPage"


const RoutesSettings = (props) => {

    const routes = useMemo(() => (
        <Routes>
            <Route
                path="/"
                element={
                    props.isSignIn ? (
                        <PageController
                            setLaunchSubApp={props.setLaunchSubApp}
                            launchSubApp={props.launchSubApp}
                        />
                    ): (
                        <SignOutMainPage
                            onLoginRequestDialogOpen={props.onLoginRequestDialogOpen}
                            onLicenseAgreementDialogOpen={props.onLicenseAgreementDialogOpen}
                        />
                    )
                }
            />
            <Route
                path="/terms-of-service"
                element={
                    <HtmlDocumentPage
                        url="/terms_of_service.html"
                    />
                }
            />
            <Route
                path="/privacy-policy"
                element={
                    <HtmlDocumentPage
                        url="/privacy_policy.html"
                    />
                }
            />
            <Route
                path="/data-deletion-instructions"
                element={
                    <HtmlDocumentPage
                        url="/data_deletion_instructions.html"
                    />
                }
            />
            <Route
                path="/signin"
                element={ <SignInPage /> }
            />


        </Routes>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [props.isSignIn, props.setLaunchSubApp, props.launchSubApp])

    return (
        <>
            {routes}
        </>
    )
}

export default RoutesSettings